import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_05.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Ultimate guide to Expense Management-Freight fast and save more",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };

  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[5].meta_keywords} />
        <meta name="description" content={META_BLOGS[5].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[5].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("5")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[5].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("5")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-5 text-center"
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>
        <h5 className="fw-light">A Journey Towards Paperless Freight</h5>
        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>November, 17 2022 - 6 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            Are you wondering how to cut down on expenses in your freight
            business? Running any business involves costs, but managing a
            trucking business can be particularly expensive. The freight
            industry presents its own set of challenges, especially when it
            comes to monitoring and controlling expenses.
          </p>

          <p>
            The cost of a single truck, let alone a fleet of them, can be a
            substantial financial burden. Expenses such as maintenance, tolls,
            repairs, and unpredictable factors like fluctuating fuel prices and
            lumper charges all contribute to the financial complexity of running
            a trucking business. It can also be challenging to establish and
            enforce credit limits for your drivers, ensuring that expenses stay
            within the allocated spending limits.
          </p>

          <p>
            However, profitability is essential for keeping your vehicles on the
            road, and minimizing expenses is a critical aspect of achieving that
            goal. In the world of freight management, various costs are
            associated with operating a fleet, from maintenance to fuel to
            insurance. For fleet managers operating with narrow profit margins,
            every effort to reduce freight expenses can have a significant
            impact on the bottom line.
          </p>

          <p>
            Here are few actionable strategies you can implement to enhance
            freight efficiency, reduce waste, and boost profitability:
          </p>

          <p>
            Track Your Freight Expenses: Managing a multitude of expenses can be
            overwhelming. Many freight expenses occur on the road, where drivers
            often rely on paper invoices or receipts. However, with MoXey, you
            can maintain digital records of all transactions, providing you with
            access to an interactive dashboard that significantly reduces cash
            leakage and streamlines expense management.
          </p>
          <p>
            Monitor Fuel Consumption: Historically, fuel has been one of the
            most substantial operating costs for freight businesses, often
            accounting for at least 25% of the operating budget. To effectively
            manage fuel consumption costs, you need visibility into your fuel
            usage data. MoXey not only offers potential per-gallon fuel
            discounts but also empowers you to set spending limits, monitor live
            transactions, prevent unauthorized usage, and detect fuel-related
            fraud, putting you in control of fuel expenses.
          </p>
          <p>
            Optimize Route Planning: Ensuring that drivers take the most
            efficient routes to their destinations can reduce total miles driven
            and help drivers avoid traffic congestion or adverse weather
            conditions, ultimately saving on fuel costs and time.
          </p>
          <p>
            Cut Your Freight Expenses with MoXey: To successfully manage a
            commercial freight business, you must continuously track, analyze,
            and assess expenses. Implementing strategies to reduce costs can
            foster a culture of operational efficiency within your organization.
            While the savings may not be immediate, they will accumulate over
            time. MoXey's smart cards can play a crucial role in cost reduction
            and freight management.
          </p>
          <p>
            With the MoXey dashboard, you can set spending limits, monitor
            real-time transactions by drivers, and receive automated insights to
            further optimize fuel expenditure.
          </p>
          <p>
            Incorporating these strategies and leveraging MoXey's solutions can
            help you streamline expenses, enhance efficiency, and ultimately
            improve the profitability of your freight business.
          </p>

          {/* BLOG ACTIONS */}
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
