import { Link } from "react-router-dom";
import "./blog-list.style.scss";

import { blogs } from "../Home/blogs.component";
import { generateBlogRoute } from "../../routes";

function BlogListComponent() {
  return (
    <div className="blogs-list">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
            <div className="row gy-5">
              {blogs.map((blog, index) => (
                <BlogCard item={blog} index={index + 1} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BlogCard = ({ item, index, animation }) => {
  return (
    <div className="col-sm-12 col-lg-4 text-dark">
      <div
        className="card p-3 rounded-4 blog-card"
        data-aos={animation ? "fade-left" : null}
        data-aos-delay={100 * index}
      >
        <Link to={`/blog/${generateBlogRoute(index)}`}>
          <img src={item.thumbnail} className="card-img-top rounded-3" />
        </Link>
        <div className="card-body">
          <Link to={`/blog/${generateBlogRoute(index)}`}>
            <h4 className="card-title fw-bold title">{item.title}</h4>
          </Link>
          <p className="card-text short-desc">{item.short_desc}</p>
          <p className="card-text card-date">
            {item.date} - {item.read}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogListComponent;
