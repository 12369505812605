import "./blog-hero.style.scss";

function BlogHeroSection() {
  return (
    <div className="blog-hero">
      <div className="container">
        <h1 className="blog-section-heading text-center fw-bold">
          Featured Blogs
        </h1>
      </div>
    </div>
  );
}

export default BlogHeroSection;
