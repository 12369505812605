import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_06.png";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Implementing a Fleet Digital Payment System: Best Practices",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[8].meta_keywords} />
        <meta name="description" content={META_BLOGS[8].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[8].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("8")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[8].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("8")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>

        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>March, 05 2023 - 5 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            In an increasingly digital world, businesses must adapt to evolving
            trends to remain competitive. One pivotal area where digitalization
            can yield significant benefits is fleet payments. Conventional
            payment methods like cash and checks are time-consuming,
            inconvenient, and prone to errors. A digital payment system can
            streamline and enhance the payment process. In this blog post, we
            will delve into best practices for implementing a fleet digital
            payment system.
          </p>
          <p>1. Understanding Your Fleet's Payment Needs</p>
          <p>
            The initial step in adopting a digital payment system is gaining a
            comprehensive understanding of your fleet's payment requirements.
            This encompasses the frequency of payments, the nature of
            transactions, and the existing payment methods. A clear grasp of
            these needs will guide you in selecting the most suitable digital
            payment solutions.
          </p>

          <p>2. Selecting the Right Payment Solution</p>
          <p>
            Numerous digital payment solutions are available in the market,
            making it essential to choose the one that aligns with your fleet's
            unique demands. Seek out a solution that offers user-friendliness,
            robust security measures, and requisite features such as real-time
            reporting and seamless integration with your accounting software. It
            is imperative that the chosen solution supports the specific payment
            methods relevant to your operations.
          </p>

          <p>3. Team Training</p>
          <p>
            After selecting a digital payment solution, thorough training of
            your team is paramount. This extends beyond your fleet drivers to
            encompass your accounting team and all relevant personnel involved
            in the payment process. Ensuring that everyone is well-versed in the
            new system and its proper usage is essential for a smooth
            transition.
          </p>

          <p>4. Effective Vendor Communication</p>
          <p>
            If your transition involves a shift from traditional payment methods
            to a digital payment system, it is crucial to communicate these
            changes with your vendors. Provide them with insights into the
            transition process and clarify how the new system will function.
            Effective communication will facilitate a seamless transition,
            minimizing any potential disruptions to your business relationships.
          </p>

          <p>5. Continuous Monitoring and Evaluation</p>
          <p>
            Ongoing monitoring and evaluation of your digital payment system's
            effectiveness are fundamental. Track time and cost savings, and
            actively seek opportunities for further system optimization. This
            may involve the automation of specific processes or the integration
            of additional features to enhance overall efficiency.
          </p>

          <p>
            At Moxey, we recognize the significance of digital payments within
            fleet management. That's why we offer a user-friendly, secure
            digital payment solution tailored to meet your fleet's unique needs.
            Our solution seamlessly integrates with your accounting software and
            enables real-time payments. Moreover, our dedicated team is readily
            available to provide training and support throughout the
            implementation process. Contact us today to discover how our
            services can assist you in implementing a digital payment system for
            your fleet.
          </p>

          {/* Action BUttons */}
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
