import "./faq.style.scss";

import { faq as FAQ_LIST } from "../../data";
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "Help - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

const categories = [...new Set(FAQ_LIST.map((item) => item.category))];

function FaqsHome() {
  const [filtered, setFiltered] = useState(FAQ_LIST);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const filterList = (e) => setSelectedCategory(e.target.value);

  useEffect(() => {
    const filteredFaqs = FAQ_LIST.filter((item) => {
      return item.category === selectedCategory;
    });
    setFiltered(filteredFaqs);
  }, [selectedCategory]);

  return (
    <div className="moxey-faq">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/faqs`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/faqs`} />
      </Helmet>
      {/* FAQ header */}
      <div className="moxey-faq-header text-center" data-aos="fade-in">
        <h1 className="moxey-faq-headline mb-4 fw-bold container">
          Frequently Asked Questions
        </h1>
        <h5 className="fw-light">Have Questions ? We are here to Help</h5>
      </div>

      {/* FAQS content */}
      <div className="container w-100">
        <div
          className="moxey-faq-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <div className="moxey-faq-content-header">
            <div className="filters w-100">
              {categories.map((category) => (
                <FaqFilter
                  category={category}
                  onClick={filterList}
                  selectedCategory={selectedCategory}
                />
              ))}
            </div>
          </div>
          {/* faqs list */}
          <div class="accordion accordion-flush" id="accordionExample">
            {filtered.map((item, index) => (
              <FaqItem item={item} index={index + 1} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const FaqItem = ({ item, index }) => {
  return (
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${item.category}-${index}`}
          aria-expanded="true"
          aria-controls={`${item.category}-${index}`}
        >
          {item.query}
        </button>
      </h2>
      <div
        id={`${item.category}-${index}`}
        class={`accordion-collapse collapse`}
      >
        <div class="accordion-body">{item.response}</div>
      </div>
    </div>
  );
};

const FaqFilter = ({ category, onClick, selectedCategory }) => {
  return (
    <div className="filter me-3">
      <input
        onClick={onClick}
        type="radio"
        class="btn-check"
        name="currentCategory"
        id={category}
        value={category}
        checked={category === selectedCategory ? true : false}
      />
      <label
        class="btn btn-outline-success rounded-pill px-4 btn-sm text-capitalize fw-bold"
        for={category}
      >
        {category}
      </label>
    </div>
  );
};

export default FaqsHome;
