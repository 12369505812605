import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_06.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "What's the best payment gateway for your Freight business?",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[6].meta_keywords} />
        <meta name="description" content={META_BLOGS[6].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[6].meta_desc} />
        <meta property="og:url" content={`${blog.base}/blog/${generateBlogRoute("6")}`} />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[6].meta_desc} />
        <meta name="twitter:image:src" content={`${blog.base}${blog.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${blog.base}/blog/${generateBlogRoute("6")}`} />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>
        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>November, 20 2022 - 5 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <h6 className="heading">Introduction</h6>
          <p>
            Payment gateways are essential for any freight business, allowing
            transporters to make payments quickly and securely. In this article,
            we will discuss the benefits of payment gateways for freight
            businesses, how to choose the right payment gateway, security
            considerations, popular payment gateways, and tips for choosing the
            right payment gateway.
          </p>
          <h6 className="heading">Benefits of Payment Gateway for Freight</h6>
          <p>
            Business Payment gateways offer a number of benefits for freight
            businesses. They provide a secure and convenient way for
            transporters to make payments, eliminating the need for manual
            processing of payments. These gateways are integrated with your
            already in-place system and takes care of fraud protection, allowing
            businesses to protect themselves from fraudulent transactions.
          </p>
          <p>
            Additionally, payment gateways can help businesses save time and
            money by streamlining the payment process. While earlier it used to
            take anywhere from 20-30 days for transporters to pay the drivers,
            reimburse all the expenses, which resulted in lack of transparency
            and low driver retention. All of this can be resolved by automating
            the payments and investing in expense management solution. MoXey
            offers instant payments, expense management and digital records of
            all your data, all in one platform.
          </p>
          <h6 className="heading"> Choosing the Right Payment Gateway</h6>
          <p>
            When choosing a payment gateway for your freight business, it's
            important to consider the features and potential fees associated
            with each option. Ideally a payment processing system allows you to
            automate payments, but lack accurate visualization of your data,
            which is imperative for you to make quicker business decisions.
          </p>
          <p>
            MoXey’s offer additional features such as recurring payments,
            scheduled payments, invoice and transaction tracking, and loyalty
            programs by partnering up with different merchants to incentivize
            drivers for making payments online.
          </p>
          <h6 className="heading">
            Security Considerations for Payment Gateway
          </h6>
          <p>
            {" "}
            Security is an important consideration when choosing a payment
            gateway for your freight business. Look for payment gateways that
            offer secure encryption and fraud protection. Let’s take MoXey’s
            case, it encrypts data, enabling safe and secure payments to keep
            your personal information safe. Additionally, MoXey makes sure the
            payment gateway is compliant with industry standards.
          </p>
          <h6 className="heading">
            Popular Payment Gateways for Freight Business
          </h6>
          <p>
            {" "}
            There are a number of popular payment gateways for businesses. Some
            of the most popular options include MoXey, PayPal, Stripe,
            Authorize.net, and Braintree. Each of these payment gateways offers
            a secure and convenient way for customers to make payments.
          </p>
          <h6 className="heading">
            Tips for Choosing the Right Payment Gateway
          </h6>
          <p>
            There are a number of automation solutions available for the freight
            industry. These solutions range from simple payment processing
            solutions to more complex solutions that integrate with existing
            systems. Some of the most popular solutions include payment
            gateways, payment processors, and accounting software.
            <br />
            <br />
            When choosing a payment gateway for your freight business, it's
            important to consider the features, fees, and security of each
            option. Additionally, make sure the payment gateway is compatible
            with your existing systems and processes.
            <br />
            <br />
            MoXey Pay is an end-to-end payment network providing backbone for
            the mobility industry. We provide direct, instant payments in an
            industry heavily reliant on cash and checks, processing billions of
            transactions every month. We work with truck drivers, carriers,
            freight brokers, and 3PLs to ensure their products quickly reach the
            MENA consumers.
            <br />
            <br />
            Automation can help to simplify the payment process and reduce the
            amount of time and resources required to manage payments. Automation
            can also help to reduce the risk of errors and ensure accuracy.
            Automation can also help to reduce costs associated with manual
            processes, such as labor costs and administrative costs.
          </p>
          <h6 className="heading">Conclusion</h6>
          <p>
            Payment gateways are essential for any freight business, allowing
            transporters to make payments quickly and securely. Additionally,
            make sure the payment gateway is compatible with your existing
            systems and processes. With the right payment gateway, your freight
            business can benefit from increased security, convenience, and cost
            savings.
          </p>
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
