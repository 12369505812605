import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";

import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_03.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Digital Payments Revolutionizing Trucking Industry",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[3].meta_keywords} />
        <meta name="description" content={META_BLOGS[3].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[3].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("3")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[3].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("3")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-3 text-center"
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          Digital payments - Innovating the Trucking Industry
        </h1>
        <h5 className="fw-light">A Journey Towards Paperless Freight</h5>
        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>May, 05 2023 - 5 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            In today's rapidly evolving world, digital payments are causing
            significant transformations in various sectors, including the
            trucking industry. These changes are bringing about increased
            efficiency, transparency, and convenience for trucking companies,
            drivers, and customers alike. In this blog post, we'll check out how
            digital payments are positively impacting the trucking industry.
          </p>
          <p>1. Streamlining Transactions:</p>
          <p>
            One of the most significant benefits of digital payments in the
            trucking industry is the streamlining of financial transactions.
            Gone are the days of cumbersome paperwork and manual processing of
            payments. With digital payments, everything happens electronically,
            reducing the administrative burden on trucking companies. This
            efficiency translates into faster transactions, fewer errors, and
            improved cash flow.
          </p>
          <p>2. Instant Payments for Drivers:</p>
          <p>
            Traditionally, truck drivers often had to wait for days or even
            weeks to receive their payments, causing financial strain. Digital
            payment solutions, such as instant money transfer apps and prepaid
            cards, now enable drivers to access their earnings immediately upon
            completing a job. This not only improves their financial stability
            but also serves as a powerful incentive for retaining and attracting
            talent in the trucking industry.
          </p>
          <p>3. Enhanced Transparency:</p>
          <p>
            Digital payments leave a clear digital trail, making financial
            transactions more transparent. This transparency benefits both
            trucking companies and their clients. Companies can easily track and
            verify payments, while customers gain greater confidence in their
            transactions, knowing that they can access detailed payment records
            effortlessly.
          </p>
          <p>4. Security and Reduced Fraud:</p>
          <p>
            Cash transactions are susceptible to theft and fraud, putting both
            drivers and trucking companies at risk. Digital payment systems
            offer advanced security features such as encryption, authentication,
            and real-time monitoring, significantly reducing the risk of fraud.
            This enhanced security provides peace of mind for all parties
            involved in the transaction.
          </p>
          <p>5. Cost Savings:</p>
          <p>
            The traditional method of cash payments involves handling, counting,
            and transporting physical currency, incurring associated costs.
            Digital payments eliminate these expenses, leading to cost savings
            for trucking companies. Additionally, the reduced risk of payment
            disputes and errors minimizes the costs related to resolving
            financial discrepancies.
          </p>
          <p>6. Efficient Expense Management:</p>
          <p>
            Digital payment solutions often come with integrated expense
            management tools. Trucking companies can track and manage expenses
            in real time, from fuel purchases to maintenance costs. This
            data-driven approach enables better decision-making, cost control,
            and budgeting for future investments.
          </p>
          <p>7. Facilitating Global Transactions:</p>
          <p>
            As the trucking industry expands globally, digital payments
            facilitate international transactions. Cross-border payments are no
            longer cumbersome and slow, thanks to digital platforms that offer
            seamless currency conversion and international fund transfers. This
            simplifies logistics and opens up new markets for trucking
            companies.
          </p>
          <p>8. Environmental Sustainability:</p>
          <p>
            Going digital isn't just about improving efficiency; it's also about
            being environmentally responsible. Reducing the need for paper-based
            transactions and physical currency circulation contributes to a
            greener planet, aligning with global sustainability goals.
          </p>

          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
