import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_10.jpeg";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Top 10 Benefits of Expense Management",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[10].meta_keywords} />
        <meta name="description" content={META_BLOGS[10].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[10].meta_desc} />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("10")}`}
        />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[10].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("10")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>

        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>August, 30 2023 - 5 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p className="lead">
            Effective spending management goes beyond simply keeping track of
            financial records in the dynamic business environment of today. It
            is essential for promoting resource efficiency and sustainable
            growth. Following are some major advantages of adopting efficient
            expense management:
          </p>
          <h6 className="heading">Cost Control:</h6>
          <p>
            Expense management enables you to track and manage spending trends,
            allowing you to spot wasteful spending and cut expenditures.
            Increased profitability is directly impacted by this.
          </p>
          <h6 className="heading">Budget adherence:</h6>
          <p>
            You can verify that your spending complies with your financial
            objectives by creating budgets for specific operational segments.
            This stops you from overspending and maintains the stability of your
            finances.
          </p>
          <h6 className="heading">Decision Making:</h6>
          <p>
            Reliable expense data facilitates well-informed choices. You can
            prioritize investments, properly distribute resources, and grasp
            growth by reviewing your expenses.
          </p>
          <h6 className="heading">Cash Flow Optimization:</h6>
          <p>
            A strong cash flow is ensured through efficient spending management.
            This accordingly enables you to benefit from favorable market
            circumstances or deal with economic problems.
          </p>
          <h6 className="heading">Transparency:</h6>
          <p>
            Transparent expense management fosters trust among stakeholders.
            Everyone gets trust in your financial processes, whether internal
            teams or external partners, increasing your reputation.
          </p>
          <h6 className="heading">Audit readiness and compliance:</h6>
          <p>
            Auditing and compliance procedures are made easier by maintaining
            organized expense records.You can easily provide evidence of
            transactions and adhere to financial regulations.
          </p>
          <h6 className="heading">Efficiency Enhancements:</h6>
          <p>
            Automation technologies speed up expenditure processing, minimizing
            manual errors and saving time. This enables your staff to
            concentrate on strategic duties as opposed to operational ones.
          </p>
          <h6 className="heading">Employee Satisfaction:</h6>
          <p>
            Employee Contentment: Clear policies and timely reimbursements are
            key components of effective spending management, which boosts
            employee motivation and satisfaction.
          </p>
          <h6 className="heading">Forecasting Accuracy:</h6>
          <p>
            Precise financial forecasting is made possible by accurate expense
            tracking.You can anticipate future costs, allocate resources
            effectively, and plan for growth.
          </p>
          <h6 className="heading">Scaling Possibilities:</h6>
          <p>
            Effective spending becomes even more crucial as your business grows.
            It offers a well-structured framework for expanding activities
            without sacrificing budgetary control.
          </p>

          {/* Action BUttons */}
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>

          <p className="lead">
            As a result, adopting a strong expense management plan is now
            essential for companies seeking sustainability and growth. It gives
            you the power to make smart choices, make the most of your
            resources, and confidently deal with the challenges of modern
            business life.
          </p>
          <p className="lead">
            If you are looking for efficient expense management, MoXey is ready
            to be your dependable partner. With its cutting-edge solutions,
            MoXey provides a simple and secure way for you to turn your expense
            management into a competitive advantage, giving you the freedom to
            devote all of your undivided attention to your primary business
            goals.
          </p>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
