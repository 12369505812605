import "./agreements.style.scss";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "Privacy Policy - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

function PrivacyComponent() {
  return (
    <div className="moxey-agreements">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/privacy`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/privacy`} />
      </Helmet>
      <h1 className="text-center">Privacy Policy</h1>
      <div className="container">
        <div className="row w-100">
          <div className="col-sm-12 col-lg-12">
            <div className="content bg-white rounded-3">
              <div class="c25 privacy-content">
                <p class="c2">
                  <span class="c3">Last updated: </span>
                  <span class="c20">December 10</span>
                  <span class="c3 c8">, 2022</span>
                </p>
                <p class="c29">
                  <span class="c3 c8">
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and conveys Your privacy rights and how
                    the law protects You.
                  </span>
                </p>
                <p class="c29">
                  <span class="c3 c8">
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                  </span>
                </p>
                <p class="c1">
                  <span class="c8 c28">Interpretation and Definitions</span>
                </p>
                <p class="c1">
                  <span class="c4">Interpretation</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </span>
                </p>
                <p class="c1">
                  <span class="c4">Definitions</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    For the purposes of this Privacy Policy:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_6-0 start">
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Account</span>
                    <span class="c3 c8">
                      &nbsp;means a unique account created for You to access our
                      Service or parts of our Service.
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Company</span>
                    <span class="c3">
                      &nbsp;(referred to as either &quot;the Company&quot;,
                      &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                      Agreement) refers to MOXEY{" "}
                    </span>
                    <span class="c20">
                      HOLDING LTD/MOXEY TECH LTD/MOXEY TECH EGYPT/MOXEY ARABIA -
                      KSA
                    </span>
                    <span class="c3 c8">.</span>
                  </li>
                  <li class="c7 c9 li-bullet-0">
                    <span class="c5">Cookies</span>
                    <span class="c3 c8">
                      &nbsp;are small files that are placed on Your computer,
                      mobile device or any other device by a website, containing
                      the details of Your browsing history on that website among
                      its many uses.
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Country</span>
                    <span class="c3">&nbsp;refers to: </span>
                    <span class="c20">
                      countries where the Company operates.{" "}
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Device</span>
                    <span class="c3 c8">
                      &nbsp;means any device that can access the Service such as
                      a computer, a cellphone or a digital tablet.
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Personal Data</span>
                    <span class="c3 c8">
                      &nbsp;is any information that relates to an identified or
                      identifiable individual.
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Service</span>
                    <span class="c3 c8">&nbsp;refers to the Website.</span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Service Provider</span>
                    <span class="c3 c8">
                      &nbsp;means any natural or legal person who processes the
                      data on behalf of the Company. It refers to third-party
                      companies or individuals employed by the Company to
                      facilitate the Service, to provide the Service on behalf
                      of the Company, to perform services related to the Service
                      or to assist the Company in analyzing how the Service is
                      used.
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Usage Data</span>
                    <span class="c3 c8">
                      &nbsp;refers to data collected automatically, either
                      generated by the use of the Service or from the Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">Website</span>
                    <span class="c3">
                      &nbsp;refers to MOXEY Technologies website, accessible
                      from&nbsp;
                    </span>
                    <span class="c10">
                      <a
                        class="c27"
                        href="https://www.google.com/url?q=https://moxey.ai/&amp;sa=D&amp;source=editors&amp;ust=1671200579525759&amp;usg=AOvVaw2AsL-vnemP-FrLRd2MahJP"
                      >
                        https://moxey.ai/
                      </a>
                    </span>
                  </li>
                  <li class="c9 c7 li-bullet-0">
                    <span class="c5">You</span>
                    <span class="c3 c8">
                      &nbsp;means the individual accessing or using the Service,
                      or the company, or other legal entity on behalf of which
                      such individual is accessing or using the Service, as
                      applicable.
                    </span>
                  </li>
                </ul>
                <p class="c1">
                  <span class="c28 c8">
                    Collecting and Using Your Personal Data
                  </span>
                </p>
                <p class="c1">
                  <span class="c4">Types of Data Collected</span>
                </p>
                <p class="c1">
                  <span class="c6">Personal Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_7-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">Email address</span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">First name and last name</span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">Phone number</span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">
                      Address, State, Province, ZIP/Postal code, City
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">Usage Data</span>
                  </li>
                </ul>
                <p class="c1">
                  <span class="c6">Usage Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Usage Data is collected automatically when using the
                    Service.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Usage Data may include information such as Your Device&#39;s
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </span>
                </p>
                <p class="c1">
                  <span class="c6">Tracking Technologies and Cookies</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_8-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">Cookies or Browser Cookies.</span>
                    <span class="c3 c8">
                      &nbsp;A cookie is a small file placed on Your Device. You
                      can instruct Your browser to refuse all Cookies or to
                      indicate when a Cookie is being sent. However, if You do
                      not accept Cookies, You may not be able to use some parts
                      of our Service. Unless you have adjusted Your browser
                      setting so that it will refuse Cookies, our Service may
                      use Cookies.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">Flash Cookies.</span>
                    <span class="c3">
                      &nbsp;Certain features of our Service may use local stored
                      objects (or Flash Cookies) to collect and store
                      information about Your preferences or Your activity on our
                      Service. Flash Cookies are not managed by the same browser
                      settings as those used for Browser Cookies. For more
                      information on how You can delete Flash Cookies, please
                      read &quot;Where can I change the settings for disabling,
                      or deleting local shared objects?&quot; available at&nbsp;
                    </span>
                    <span class="c10"></span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">Web Beacons.</span>
                    <span class="c3 c8">
                      &nbsp;Certain sections of our Service and our emails may
                      contain small electronic files known as web beacons (also
                      referred to as clear gifs, pixel tags, and single-pixel
                      gifs) that permit the Company, for example, to count users
                      who have visited those pages or opened an email and for
                      other related website statistics (for example, recording
                      the popularity of a certain section and verifying system
                      and server integrity).
                    </span>
                  </li>
                </ul>
                <p class="c2">
                  <span class="c3">
                    Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                    Cookies. Persistent Cookies remain on Your personal computer
                    or mobile device when You go offline, while Session Cookies
                    are deleted as soon as You close Your web browser. Learn
                    more about cookies on the&nbsp;
                  </span>
                  <span class="c3 c15">
                    <a
                      class="c27"
                      href="https://www.google.com/url?q=https://www.privacypolicies.com/blog/privacy-policy-template/%23Use_Of_Cookies_Log_Files_And_Tracking&amp;sa=D&amp;source=editors&amp;ust=1671200579528182&amp;usg=AOvVaw2kgJfOj8TicMtWcPxVv5A1"
                    >
                      Privacy Policies website
                    </a>
                  </span>
                  <span class="c3 c8">&nbsp;article.</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_1-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">Necessary / Essential Cookies</span>
                  </li>
                </ul>
                <p class="c2 c16">
                  <span class="c3 c8">Type: Session Cookies</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">Administered by: Us</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">
                    Purpose: These Cookies are essential to provide You with
                    services available through the Website and to enable You to
                    use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.
                  </span>
                </p>
                <ul class="c0 lst-kix_list_1-0">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">
                      Cookies Policy / Notice Acceptance Cookies
                    </span>
                  </li>
                </ul>
                <p class="c2 c16">
                  <span class="c3 c8">Type: Persistent Cookies</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">Administered by: Us</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">
                    Purpose: These Cookies identify if users have accepted the
                    use of cookies on the Website.
                  </span>
                </p>
                <ul class="c0 lst-kix_list_1-0">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">Functionality Cookies</span>
                  </li>
                </ul>
                <p class="c2 c16">
                  <span class="c3 c8">Type: Persistent Cookies</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">Administered by: Us</span>
                </p>
                <p class="c2 c16">
                  <span class="c3 c8">
                    Purpose: These Cookies allow us to remember choices You make
                    when You use the Website, such as remembering your login
                    details or language preference. The purpose of these Cookies
                    is to provide You with a more personal experience and to
                    avoid You having to re-enter your preferences every time You
                    use the Website.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy
                    or the Cookies section of our Privacy Policy.
                  </span>
                </p>
                <p class="c1">
                  <span class="c4">Use of Your Personal Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The Company may use Personal Data for the following
                    purposes:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_2-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">To provide and maintain our Service</span>
                    <span class="c3 c8">
                      , including to monitor the usage of our Service.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">To manage Your Account:</span>
                    <span class="c3 c8">
                      &nbsp;to manage Your registration as a user of the
                      Service. The Personal Data You provide can give You access
                      to different functionalities of the Service that are
                      available to You as a registered user.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">For the performance of a contract:</span>
                    <span class="c3 c8">
                      &nbsp;the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">To contact You:</span>
                    <span class="c3 c8">
                      &nbsp;To contact You by email, telephone calls, SMS, or
                      other equivalent forms of electronic communication, such
                      as a mobile application&#39;s push notifications regarding
                      updates or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">To provide You</span>
                    <span class="c3 c8">
                      &nbsp;with news, special offers and general information
                      about other goods, services and events which we offer that
                      are similar to those that you have already purchased or
                      enquired about unless You have opted not to receive such
                      information.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">To manage Your requests:</span>
                    <span class="c3 c8">
                      &nbsp;To attend and manage Your requests to Us.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">For business transfers:</span>
                    <span class="c3 c8">
                      &nbsp;We may use Your information to evaluate or conduct a
                      merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of
                      Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      Personal Data held by Us about our Service users is among
                      the assets transferred.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">For other purposes</span>
                    <span class="c3 c8">
                      : We may use Your information for other purposes, such as
                      data analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </span>
                  </li>
                </ul>
                <p class="c2">
                  <span class="c3 c8">
                    We may share Your personal information in the following
                    situations:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_3-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">With Service Providers:</span>
                    <span class="c3 c8">
                      &nbsp;We may share Your personal information with Service
                      Providers to monitor and analyze the use of our Service,
                      to contact You.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">For business transfers:</span>
                    <span class="c3 c8">
                      &nbsp;We may share or transfer Your personal information
                      in connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of Our business to another company.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">With Affiliates:</span>
                    <span class="c3 c8">
                      &nbsp;We may share Your information with Our affiliates,
                      in which case we will require those affiliates to honor
                      this Privacy Policy. Affiliates include Our parent company
                      and any other subsidiaries, joint venture partners or
                      other companies that We control or that are under common
                      control with Us.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">With business partners:</span>
                    <span class="c3 c8">
                      &nbsp;We may share Your information with Our business
                      partners to offer You certain products, services or
                      promotions.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">With other users:</span>
                    <span class="c3 c8">
                      &nbsp;when You share personal information or otherwise
                      interact in the public areas with other users, such
                      information may be viewed by all users and may be publicly
                      distributed outside.
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c5">With Your consent</span>
                    <span class="c3 c8">
                      : We may disclose Your personal information for any other
                      purpose with Your consent.
                    </span>
                  </li>
                </ul>
                <p class="c1">
                  <span class="c4">Retention of Your Personal Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </span>
                </p>
                <p class="c1">
                  <span class="c4">Transfer of Your Personal Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Your information, including Personal Data, is processed at
                    the Company&#39;s operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to &mdash;
                    and maintained on &mdash; computers located outside of Your
                    state, province, country or other governmental jurisdiction
                    where the data protection laws may differ than those from
                    Your jurisdiction.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </span>
                </p>
                <p class="c1">
                  <span class="c4">Disclosure of Your Personal Data</span>
                </p>
                <p class="c1">
                  <span class="c6">Business Transactions</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </span>
                </p>
                <p class="c1">
                  <span class="c6">Law enforcement</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </span>
                </p>
                <p class="c1">
                  <span class="c6">Other legal requirements</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_4-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">Comply with a legal obligation</span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">
                      Protect and defend the rights or property of the Company
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">
                      Protect the personal safety of Users of the Service or the
                      public
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3 c8">Protect against legal liability</span>
                  </li>
                </ul>
                <p class="c1">
                  <span class="c4">Security of Your Personal Data</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                  </span>
                </p>
                <p class="c1">
                  <span class="c13 c8">Children&#39;s Privacy</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent&#39;s
                    consent before We collect and use that information.
                  </span>
                </p>
                <p class="c1">
                  <span class="c13 c8">Links to Other Websites</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party&#39;s site. We strongly
                    advise You to review the Privacy Policy of every site You
                    visit.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </span>
                </p>
                <p class="c1">
                  <span class="c8 c13">Changes to this Privacy Policy</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the &quot;Last updated&quot; date at the top of this
                    Privacy Policy.
                  </span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </span>
                </p>
                <p class="c1">
                  <span class="c13 c8">Governing Law </span>
                </p>
                <p class="c30">
                  <span class="c3">
                    This Policy shall be construed and controlled by the laws{" "}
                  </span>
                  <span class="c20">of the United Arab Emirates</span>
                  <span class="c3">. The laws of </span>
                  <span class="c20">Dubai &nbsp;</span>
                  <span class="c3">
                    will govern any dispute arising from the terms of this
                    Policy or a breach of this Policy, and the User agrees to
                    the non-exclusive jurisdiction of{" "}
                  </span>
                  <span class="c20">DIFC</span>
                  <span class="c3 c8">courts.</span>
                </p>
                <p class="c1" id="h.gjdgxs">
                  <span class="c13 c8">Contact Us</span>
                </p>
                <p class="c2">
                  <span class="c3 c8">
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                  </span>
                </p>
                <ul class="c0 lst-kix_list_5-0 start">
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3">
                      By visiting this page on our website:&nbsp;
                    </span>
                    <span class="c10">
                      <a class="c27">https://moxey.ai/</a>
                    </span>
                  </li>
                  <li class="c2 c7 li-bullet-0">
                    <span class="c3">By </span>
                    <span class="c20">email</span>
                    <span class="c3">:</span>
                    <span class="c20">
                      <a href="mailto:Contact@moxey.ai">Contact@moxey.ai</a>
                    </span>
                  </li>
                </ul>
                <p class="c26">
                  <span class="c14 c8"></span>
                </p>
                <div>
                  <p class="c17">
                    <span class="c8 c23"></span>
                  </p>
                  <p class="c17">
                    <span class="c23 c8"></span>
                  </p>
                  <p class="c18" dir="rtl">
                    <span class="c8 c31">
                      MOXEY Technologies &ndash; Web Privacy policy v.1 0622
                    </span>
                  </p>
                  <p class="c12">
                    <span class="c8 c14"></span>
                  </p>
                  <p class="c12">
                    <span class="c14 c8"></span>
                  </p>
                  <p class="c26">
                    <span class="c14 c8"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyComponent;
