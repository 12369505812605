import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_06.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "The Future of Fleet Payments: Emerging Trends and Innovations",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[9].meta_keywords} />
        <meta name="description" content={META_BLOGS[9].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[9].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("9")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[9].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("9")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>

        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>March, 9 2023 - 4 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            The landscape of fleet payments is evolving rapidly, driven by
            emerging trends and innovations that promise to reshape how
            businesses manage their vehicle fleets. In this blog, we will
            explore the exciting developments in fleet payments and their
            potential to revolutionize the industry.
          </p>
          <p>1. Digital Payments Take Center Stage:</p>
          <p>
            One of the most prominent trends in fleet payments is the increasing
            shift towards digital payment methods. With the rise of mobile
            payments and digital wallets, businesses are recognizing the
            advantages of going digital. Digital payment solutions offer
            benefits such as faster transactions, enhanced spending control, and
            reduced administrative overhead. They empower fleet managers to
            streamline payments, monitor expenses in real-time, and gain greater
            insights into their financial operations.
          </p>
          <p>2. Data Analytics for Efficiency:</p>
          <p>
            Data analytics is becoming a game-changer in fleet management. By
            collecting and analyzing data on vehicle usage, maintenance
            schedules, fuel consumption, and driver behavior, businesses can
            optimize their fleets. These insights enable more efficient route
            planning, reduced idle time, better maintenance scheduling, and cost
            reduction. Data-driven decisions not only enhance efficiency but
            also contribute to a more sustainable and eco-friendly fleet
            operation.
          </p>
          <p>3. Innovations in Payment Technology:</p>
          <p>
            Innovations in payment technology are transforming fleet payments.
            Some companies are exploring blockchain-based payment systems to
            provide increased transparency and security. Blockchain can securely
            record and verify every transaction, reducing the risk of fraud and
            ensuring the integrity of payment records. Additionally, biometric
            authentication methods, such as facial recognition, are being
            considered to ensure that only authorized personnel can access fleet
            payment accounts, enhancing security further.
          </p>
          <p>4. Customized Spending Controls:</p>
          <p>
            Modern fleet payment solutions allow businesses to set custom
            spending limits for individual vehicles or drivers. This feature
            helps manage expenses effectively and prevent overspending. Fleet
            managers can establish specific spending thresholds, ensuring that
            resources are allocated efficiently and in line with the budget.
          </p>
          <p>5. Integration and Automation:</p>
          <p>
            The future of fleet payments includes seamless integration with
            other fleet management tools and automation of payment processes.
            Integration with accounting software and expense management systems
            streamlines financial operations, reducing manual data entry and the
            risk of errors. Automation helps ensure that payments are processed
            on time, reducing delays and potential disruptions to fleet
            operations.
          </p>
          <p>6. Environmental Sustainability:</p>
          <p>
            Sustainability is a growing concern in fleet management. Many
            businesses are transitioning to eco-friendly vehicles and adopting
            practices that reduce their carbon footprint. Digital payments
            contribute to sustainability by reducing the need for paper-based
            transactions, cutting down on the use of physical currency, and
            streamlining operations, leading to reduced fuel consumption and
            emissions.
          </p>
          <p>Conclusion</p>
          <p>
            The future of fleet payments is exciting, with emerging trends and
            innovations poised to transform the industry. From the widespread
            adoption of digital payments to the integration of data analytics
            and cutting-edge payment technologies, businesses can look forward
            to more efficient, secure, and sustainable fleet payment solutions.
            Staying ahead of these trends will be key to ensuring that your
            fleet management remains competitive and efficient in the evolving
            landscape of the industry.
          </p>

          {/* Action BUttons */}
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
