import { Link } from "react-router-dom";
import { HashLink as HLink } from "react-router-hash-link";
import { useState, useEffect } from "react";

import "./navbar.style.scss";
import NextIcon from "./next_icon.svg";
import CloseIcon from "./close_icon.svg";
import PromoBannerImg from "./promo_banner.jpg";

import MoxeyLogo from "./logo.component";
import DropdownNavItem from "./navbar-dropdown-item.component";

const menu = [
  {
    title: "products",
    items: [
      {
        name: "moXey pay",
        id: "#MoxeyPay",
        url: "",
      },
      {
        name: "moXey SCF",
        id: "#MoxeyScf",
        url: "",
      },
      {
        name: "moXey Leasing",
        id: "#MoxeyLeasing",
        url: "",
      },
      {
        name: "moXey Buy Now Pay Later",
        id: "#MoxeyBnpl",
        url: "",
      },
      {
        name: "moXey invoice factoring",
        id: "#MoxeyFactoring",
        url: "",
      },
    ],
  },
  {
    title: "features",
    items: [
      {
        name: "fleet partners",
        id: "#fleet-partners",
        url: "/",
      },
      {
        name: "transporters",
        id: "#transporters",
        url: "/",
      },
      {
        name: "merchants",
        id: "#merchants",
        url: "/",
      },
    ],
  },
  {
    title: "publications",
    items: [
      {
        name: "Blogs",
        url: "/Blogs",
        id: "Blogs",
      },
    ],
  },
];

const menuMap = {
  products: 1,
  features: 2,
  publications: 3,
};

const NavbarComponent = () => {
  const [isBanner, setIsBanner] = useState(false);
  const [currentOpenMenu, setCurrentOpenMenu] = useState(null);

  const closeBanner = () => setIsBanner(false);
  const openBanner = (e) => {
    if (isBanner && currentOpenMenu === menuMap[e.target.innerHTML]) return;
    setCurrentOpenMenu(menuMap[e.target.innerHTML]);
    setIsBanner(true);
  };

  const [offset, setOffset] = useState(0);

  const scrollToTop = ()=> window.scrollTo(0, 0);

  const onScroll = () => setOffset(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark moxey-navbar fixed-top px-lg-5 py-4">
        <div className="container">
          <HLink to="/" className="navbar-brand me-5" onClick={scrollToTop}>
            <MoxeyLogo />
          </HLink>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            data-aos="fade-in"
          >
            {/* left navbar items */}
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <HLink className="nav-link" to="/#moxey-about" onClick={closeBanner}>
                  <span className="nav-text">About Us</span>
                </HLink>
              </li>
              {menu.map((item, index) => (
                <DropdownNavItem
                  item={item}
                  closeBanner={closeBanner}
                  openBanner={openBanner}
                  index={index + 1}
                  key={index}
                />
              ))}
            </ul>

            {/* right navbar items */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mx-0">
              {/* <li className="nav-item dropdown ">
                <a
                  className="nav-link "
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select Language
                </a>
                <ul className="dropdown-menu bg-transparent">
                  <li>
                    <a
                      className="dropdown-item text-light dropdown-item-text text-decoration-none"
                      href="#"
                    >
                      English (EN)
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item text-light dropdown-item-text text-decoration-none"
                      href="#"
                    >
                      Arabic (AR)
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item me-0">
                <ul className="navbar-nav mb-2">
                  <li className="nav-item m-0">
                    <a
                      className="btn btn-outline-light rounded-pill px-4 py-1 action-btn"
                      type="button"
                      href="https://pay.moxey.ai/cards-ui/authenticate/login"
                      target="_blank"
                    >
                      Log In
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {isBanner ? (
        <div className="ad-banner" onClick={closeBanner}>
          <div className="ad-banner-body" onClick={closeBanner}>
            <div className="container close" onClick={closeBanner}>
              {/* <button className="btn p-0 py-2" onClick={closeBanner}>
                <img src={CloseIcon} alt="" />
                <span className="text-light ms-3">Close</span>
              </button> */}
            </div>
            {/* content */}
            {/* <div className="ad-banner-content">
              <div className="container">
                {currentOpenMenu === 1 ? (
                  <img
                    src={PromoBannerImg}
                    className="ad-banner-img rounded-4 shadow"
                  />
                ) : null}
                {currentOpenMenu === 2 ? (
                  <img
                    src={PromoBannerImg}
                    className="ad-banner-img rounded-4 shadow"
                  />
                ) : null}
                {currentOpenMenu === 3 ? (
                  <img
                    src={PromoBannerImg}
                    className="ad-banner-img rounded-4 shadow"
                  />
                ) : null}
              </div>
            </div> */}
          </div>
        </div>
      ) : null}

      <div
        className="offcanvas offcanvas-end text-bg-dark sidebar"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header d-flex justify-content-end">
          <button
            type="button"
            className="btn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="menu-wrapper" data-bs-dismiss="offcanvas">
            <HLink
              to="/#moxey-about"
              className="btn btn-link text-light text-decoration-none fw-bold"              
            >
              About Us
            </HLink>
          </div>

          {menu.map((item, index) => (
            <SidebarMenu item={item} key={index} />
          ))}

          <a
            className="btn btn-outline-light rounded-pill px-4 py-1 action-btn mt-5"
            type="button"
            href="https://pay.moxey.ai/cards-ui/authenticate/login"
            target="_blank"
          >
            Log In
          </a>
        </div>
      </div>
    </>
  );
};
const SidebarMenu = ({ item }) => {
  return (
    <div className="menu-wrapper">
      <button
        className="btn btn-link text-light text-decoration-none fw-bold  text-capitalize"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#menu-${item.title}`}
        aria-expanded="false"
        aria-controls={`menu-${item.title}`}
      >
        {item.title}
        <img className={`nav-icon ms-2 mb-1`} src={NextIcon} alt="next icon" />
      </button>

      <div className="collapse" id={`menu-${item.title}`}>
        <ul className="menu-bar">
          {item.items.map((i, index) => (
            <li data-bs-dismiss="offcanvas" className="mb-2" key={index}>
              <HLink
                to={`/${i.id}`}
                className="text-light text-decoration-none text-capitalize"
              >
                {i.name}
              </HLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavbarComponent;
