export function OnlyInPc({ children }) {
  return (
    <template className="d-none d-md-done d-lg-block">{children}</template>
  );
}

export function OnlyInMobile({ children }) {
  return (
    <template className="d-block d-md-none d-lg-none">{children}</template>
  );
}
