import "./404.style.scss";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const metaData = {
  title: "404 Not Found - Moxey.ai",
  base: "https://moxey.ai",
  keywords: "page not found, 404 error",
  description: "We could not find what you were looking for on Moxey.",
};

function NotFound() {
  return (
    <div className="notfound">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={metaData.base} />
        <meta property="og:image" content="" />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={metaData.base} />
      </Helmet>
      <h1 className="mt-5 text-center display-6">Page Not Found</h1>
      <div className="mt-5">
        <Link to="/" className="text-white">
          Go Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
