import "./about.style.scss";
import MoxeyLogo from "./imgs/moxey-logo.svg";

// components
import PrimaryActionButton from "../ui/Buttons/primary-button.component";

const AboutComponent = () => {
  return (
    <div className="moxey-about" id="moxey-about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3
              className="moxey-about-line-1 text-uppercase fw-light"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              introducing
            </h3>
            <img
              className="moxey-about-logo"
              src={MoxeyLogo}
              data-aos="fade-in"
              data-aos-delay="250"
            />
            <h1
              className="moxey-about-line-2 text-capitalize"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              Redesign your payment ecosystem
            </h1>
          </div>
          <div className="col-lg-6">
            <div
              className="moxey-about-info"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <p className="moxey-about-info-text">
                Moxey provides mobility & logistic sectors with the tech
                platform needed for a digitally open financial system.
              </p>
              <p className="moxey-about-info-text">
                We are a technology company building banking & payment
                infrastructure for the mobility sector in MENA.
              </p>
              <p className="moxey-about-info-text">
                Mobility players of every size, be it truck drivers, brokers,
                operators to the public companies can use the Moxey platform to
                accept online payments and run complex financial operations.
              </p>
              <p className="moxey-about-info-text">
                If you choose MoXey, congratulations and welcome.
              </p>
              <h2 className="moxey-about-info-line-1">
                We are excited to serve you!
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
