import "./button.style.scss";

const ButtonComponent = ({ name, className, onClick, icon }) => {
  return (
    <button
      className={`btn btn-lg secondary-action-btn text-capitalize text-light ${className}`}
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
      onClick={onClick}
    >
      {icon && <img className="me-2" src={icon} />}
      <span className="btn-text">{name}</span>
    </button>
  );
};

export default ButtonComponent;
