import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";
import { BlogCard, blogs } from "../../components/Home/blogs.component";

import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_01.png";

import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
const blog = {
  title: "Save More with MoXey — The Fleet Card That's Accepted Everywhere",
  thumbnail: BlogThumbnail,
  base: "https://moxey.ai",
};
function BlogView() {
  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[1].meta_keywords} />
        <meta name="description" content={META_BLOGS[1].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[1].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("1")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[1].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("1")}`}
        />
      </Helmet>
      <div className="moxey-blog">
        {/* blog header */}
        <div
          className="moxey-blog-header blog-bg-1 text-center"
          data-aos="fade-in"
        >
          <h1 className="moxey-blog-headline mb-4 fw-bold container">
            Save More with MoXey — The Fleet Card That's Accepted Everywhere
          </h1>
          <h5 className="fw-light">Sign up Now</h5>
          <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
            <div className="me-3">
              <small>November, 01 2022 - 5 min read</small>
            </div>
            <div>
              <RWebShare
                data={{
                  text: document.title,
                  url: document.URL,
                  title: blog.title,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className="btn btn-sm rounded-circle">
                  <img src={shareIcon} />
                </button>
              </RWebShare>

              <button className="btn btn-sm rounded-circle">
                <img src={bookmarkIcon} />
              </button>
            </div>
          </div>
        </div>

        {/* blog content */}
        <div className="container">
          <div
            className="moxey-blog-content shadow"
            data-aos="fade-up"
            data-aos-delay="350"
          >
            <p>
              <span>Introducing Fleet Cards:</span>{" "}
              Transitioning to a Paperless Freight Journey for Big Savings
            </p>
            <p>
              In today's cashless era, where debit cards, credit cards, and
              discount cards have become the norm, the trucking industry still
              heavily relies on cash transactions. This leads to cash leakages
              and unaccounted expenses. To address this issue, fuel cards, fleet
              cards, and expense cards have emerged as digital solutions to
              digitize transactions and enhance fleet management.
            </p>
            <p>
              But how exactly do these fuel cards work, and what benefits do
              they offer? Let's explore:
            </p>
            <p>Streamlined Fleet Management:</p>
            <p>
              Fuel cards act like credit cards tailored to meet the expenses
              incurred by drivers during freight moves. They enable both small
              and large transporters to simplify operations. With a fleet card,
              you gain real-time reporting of all transactions, customizable to
              provide powerful insights for efficient fleet management.
            </p>
            <p>Digital Paperless Documentation:</p>

            <p>
              Fleet cards allow you to record and monitor information digitally,
              surpassing the limitations of traditional paper-driven manual
              documentation. By leveraging up-to-date reports on driver
              activity, managing credit limits, and monitoring expenses through
              a dedicated online account, you can streamline driver onboarding
              and ensure comprehensive documentation.
            </p>

            <p>Hassle-Free Expense Control:</p>

            <p>
              Monitoring expenses has always been a major challenge in the
              trucking industry. Fleet cards empower you to set credit limits
              for your drivers, providing control over their spending. Adjusting
              the credit limit becomes effortless, and you can easily ensure
              that expenses fall within the assigned limits.
            </p>

            <p>Enhanced Fraud Protection:</p>

            <p>
              Fleet cards employ highly secure payment systems to prevent fraud
              and potential loss of funds, which can significantly impact
              insurance premiums. Advanced authentication procedures minimize
              the risk of fraudulent activities, offering peace of mind and
              financial security.
            </p>

            <p>Building Your Credit:</p>
            <p>
              By capturing all transactions and payments, fleet cards contribute
              to building a good credit score. This information is shared with
              credit bureaus, making you eligible for higher spending limits and
              lower interest rates.
            </p>

            <p>
              Are You Really Saving with Fleet Cards?
            </p>
            <p>
              Fleet cards play a crucial role in keeping operating costs low by
              monitoring various activities associated with freight moves. For
              instance, MoXey's Pre-loaded smart cards, powered by VISA, are
              widely accepted and cover all fleet-related expenses, including
              tolls, repairs, maintenance, insurance, and even online shopping.
            </p>
            <p>
              MoXey's Card offers a range of services to streamline your
              processes, providing powerful insights and control. Enjoy the
              following benefits:
            </p>
            <p>No Hidden Fees/Charges:</p>
            <p>
              Opt for a fuel card that offers low or no operating fees and has
              zero spending requirements to increase your overall savings.
            </p>
            <p>Unlimited Discounts:</p>
            <p>
              Some fuel card providers impose limits on fuel discounts,
              typically capping them at a certain volume. By choosing a card
              with no limits, you can maximize your savings without
              restrictions.
            </p>
            <p>Significant Savings:</p>
            <p>
              Beyond fuel, a comprehensive fuel card covers repairs, parts,
              insurance, and more, freeing up cash for other expenses like
              employee payroll, allowing you to save big.
            </p>
            <p>Get Started with MoXey:</p>
            <p>Take advantage of MoXey's offerings by signing up now.</p>
            <p>
              Join MoXey today to unlock significant savings. Sign up now and
              experience the convenience and efficiency of a fleet card that
              caters to all your needs.
            </p>

            <div className="actions my-5">
              <div className="d-grid col-sm-12 col-lg-2 mx-auto">
                <Link
                  to="/contact"
                  className="btn btn-success py-2  rounded-pill"
                >
                  Request Demo
                </Link>
              </div>
            </div>
          </div>

          {/* moxey related blogs list */}
          <h4 className="text-center mt-5 py-5">Read more</h4>
          <div className="moxey-recommendations">
            {RELATED_BLOGS_LIST.map((blog, index) => (
              <BlogCard item={blog} index={index} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogView;
