import "./agreements.style.scss";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "Terms of Use - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

function PrivacyComponent() {
  return (
    <div className="moxey-agreements">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/terms`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/terms`} />
      </Helmet>
      <h1 className="text-center">MoXey Terms of Use</h1>
      <div className="container">
        <div className="row w-100">
          <div className="col-sm-12 col-lg-12">
            <div className="content bg-white rounded-3">
              <div class="c10 terms-content">
                <div>
                  <p class="c14">
                    <span class="c12 c15"></span>
                  </p>
                </div>
                <p class="c5">
                  <span class="c11"></span>
                </p>
                <p class="c0">
                  <span class="c7">FOR USERS OF MOXEY&rsquo;S WEBSITE</span>
                </p>
                <p class="c0">
                  <span class="c8">
                    Thank you for visiting MOXEY, INC.&rsquo;s
                    (&ldquo;Company&rdquo; or &ldquo;We&rdquo; or
                    &ldquo;Us&rdquo; or &ldquo;MOXEY&rdquo;) internet website at{" "}
                  </span>

                  <span class="c1">
                    &nbsp;(the &ldquo;Site&rdquo;). We provide these Terms of
                    Use (the &ldquo;Agreement&rdquo;) to inform users of our
                    policies with regard to the terms and conditions governing
                    use of this Site. Use of this Site is conditioned on
                    acceptance, without modification, of this Agreement by you
                    as a user of this Site (&ldquo;You&rdquo; or
                    &ldquo;User&rdquo;).
                  </span>
                </p>
                <p class="c0 c16">
                  <span class="c1"></span>
                </p>
                <p class="c0">
                  <span class="c1">
                    IF YOU CHOOSE TO ACCEPT THIS AGREEMENT BY VISITING AND
                    CONTINUING TO USE THIS WEBSITE, YOU AGREE TO BECOME BOUND BY
                    THE TERMS AND CONDITIONS OF THIS AGREEMENT AS IT IS
                    PRESENTED TO YOU AS OF THE DATE OF SUCH ASSENT (THE
                    &ldquo;EFFECTIVE DATE&rdquo;). NO CHANGES (ADDITIONS OR
                    DELETIONS) TO THIS AGREEMENT WILL BE ACCEPTED BY MOXEY. IF
                    YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS
                    AGREEMENT, YOU WILL NOT HAVE ANY RIGHT TO USE THIS SITE. YOU
                    SHOULD DOWNLOAD AND PRINT THIS AGREEMENT FOR YOUR RECORDS.
                  </span>
                </p>
                <p class="c0 c16">
                  <span class="c1"></span>
                </p>
                <p class="c0">
                  <span class="c7">
                    By visiting this Site, you agree to the following:
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    1. Definitions. In addition to all other defined terms in
                    this Agreement, the following terms have the following
                    meaning: (i) &ldquo;MOXEY Server&rdquo; means the computer
                    software or hardware that serves and hosts the Site to users
                    across the Internet; and (ii)&ldquo;User(s)&rdquo; means You
                    and any other end users gaining access to the Site through
                    You.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2. Access; Conditions to Use of the Site.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.1 Subject to all of the terms and conditions of this
                    Agreement, MOXEY hereby grants to User a non-exclusive,
                    revocable, and limited right to access and use the Site in
                    strict compliance with this Agreement (&ldquo;Access
                    Right&rdquo;). MOXEY reserves the right to suspend or revoke
                    this Access Right at our discretion without notice.
                    Notwithstanding the foregoing Access Right, MOXEY also has
                    the right to change, suspend, or discontinue any (or all)
                    aspects or features of the Site at any time, and from time
                    to time, including the availability of any content or
                    features on the Site. This Access Right granted to User
                    under this Agreement will immediately terminate upon the
                    expiration, cancellation or termination of this Agreement
                    for any reason.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.2 User shall be responsible for obtaining and maintaining
                    any equipment or ancillary services needed to connect to or
                    access the Site, including, without limitation, Internet
                    connections, modems, hardware, software, and long distance
                    or local telephone service.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.3 As a condition of User&rsquo;s use of the Site, User
                    covenants to MOXEY that User will not use the Site for any
                    unlawful purpose or for any purpose that is prohibited by
                    this Agreement. User may not use the Site in any manner that
                    could damage, disable, overburden, or impair the Site and
                    any MOXEY Servers, or interfere with any other party&rsquo;s
                    use and enjoyment of the Site. User may not obtain or
                    attempt to obtain any materials or information through any
                    means not intentionally made available or provided for
                    through the Site.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.4 Notwithstanding any assistance that MOXEY may provide,
                    the User assumes sole responsible for the uploading and
                    updating of any of the User&rsquo;s Content (if any) as may
                    be available through the Site.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.5 Without limiting the generality of the foregoing, User
                    agrees to all of the following provisions: (a) User will not
                    upload to, distribute or otherwise publish through the Site
                    any data, information, messages, text, works, material or
                    any other content, including, without limitation, any
                    personal identifiable information related to the User
                    (collectively, &ldquo;Content&rdquo;) that is unlawful,
                    libelous, defamatory, invasive of privacy or publicity
                    rights, harassing, threatening, abusive, inflammatory,
                    obscene, or otherwise objectionable; (b) User will not
                    upload or transmit any Content that would violate the rights
                    of any party, would constitute or encourage a criminal
                    offense, or would otherwise create liability or violate any
                    local, state, federal or international law; (c) User will
                    not upload or transmit any Content that may infringe any
                    patent, trademark, trade secret, copyright, or other
                    intellectual or proprietary right of any party anywhere; (d)
                    User will not impersonate any person or entity or otherwise
                    misrepresent the User&rsquo;s affiliation with a person or
                    entity; (e) User will not distribute or publish unsolicited
                    promotions, advertising, or solicitations for any goods,
                    services or money, including junk mail and junk e-mail; and
                    (f) User will not use the Site for purposes not authorized
                    by MOXEY.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.6 Without limiting the generality of the foregoing, User
                    agrees to all of the following provisions: (a) User is
                    prohibited from violating or attempting to violate the
                    security of the Site or the MOXEY Server, including, without
                    limitation: (i) accessing data not intended for such User or
                    logging into a server or account which the User is not
                    authorized to access; (ii) attempting to probe, scan or test
                    the vulnerability of a system or network or to breach
                    security or authentication measures without proper
                    authorization; (iii) attempting to interfere with service to
                    any user, host, or network; or (iv) sending unsolicited
                    e-mail, including promotions and/or advertising of products
                    or services; (b) any violation of any system or network
                    security (including, but not limited to, that of the Site or
                    the MOXEY Server) may result in civil or criminal liability;
                    and (c) MOXEY has the right to investigate occurrences that
                    may involve such violations and may involve, and cooperate
                    with, law enforcement authorities in prosecuting Users who
                    are involved in such violations. MOXEY also reserves the
                    right to cooperate with any and all law enforcement
                    agencies, including complying with warrants, court orders
                    and subpoenas and disclosing to law enforcement agencies any
                    information about any User and anything a User does with
                    respect to the Site. By User&rsquo;s use of the Site, User
                    authorizes MOXEY to take such action.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.7 In the event the Site, now or in the future, allows User
                    to upload, transmit, or otherwise contribute any Content to
                    the Site, User hereby represents and warrants to MOXEY that
                    User has the lawful right to distribute and reproduce such
                    Content. Also, User is solely responsible for its conduct
                    (and the conduct of its users) while using the Site,
                    including, but not limited to, all Content in any folders or
                    web pages (if any), or through any other transactions or
                    interactions User generates, transmits, or maintains via the
                    Site. MOXEY takes no responsibility for any such online
                    distribution or publication by User or by any other party.
                    MOXEY cannot and will not review every message or other
                    Content that User or any other party may generate or post,
                    and MOXEY is not responsible for the Content thereof.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    2.8 In addition to any other right to terminate this
                    Agreement, MOXEY hereby has the absolute right to
                    immediately terminate, without warning, any account which it
                    believes, in its sole discretion, breaches any of the
                    provisions of this Section 2.
                  </span>
                </p>
                <p class="c0">
                  <span class="c7">
                    3. Copyrights and Other Intellectual Property Rights;
                    Reservation of Rights.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    3.1 MOXEY&rsquo;s policy is to respect the copyright and
                    intellectual property rights of others. MOXEY has the
                    absolute right to (i) immediately terminate, without
                    warning, all rights (including, without limitation, all
                    Access Rights) of any Users who (in MOXEY&rsquo;s
                    determination) appear to infringe upon the copyright or
                    intellectual property rights of others, and (ii) remove any
                    Content from the Site that, in MOXEY&rsquo;s determination,
                    may infringe the copyright or other intellectual property
                    rights of any third party.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    3.2 This Agreement shall not be interpreted to transfer any
                    rights in any intellectual property from MOXEY to any Users.
                    MOXEY or its licensors shall solely own all inventions,
                    patents, trademarks/service marks, logos, images, graphics,
                    content, reports, analysis, data, formulae, processes,
                    techniques, software, website designs, all other copyrights,
                    and all other intellectual property provided in, or made
                    available by using, or otherwise contained in, the MOXEY
                    Site and otherwise provided in furtherance of this Agreement
                    (collectively, &ldquo;MOXEY IP Assets&rdquo;).
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    3.3 MOXEY IP Assets may not be used by User without the
                    prior written permission from MOXEY, and then only with
                    proper acknowledgement. Any rights not expressly granted
                    herein to User are reserved to MOXEY. In addition to any
                    other conditions on User&rsquo;s Access Right as set forth
                    in this Agreement, User&rsquo;s Access Right is subject to
                    the following additional conditions: (i) User shall not
                    modify, disassemble, decompile or reverse translate or
                    create derivative works from any of the MOXEY IP Assets or
                    otherwise attempt to derive any source code of the same or
                    let any third party do the same; (ii) no copyrighted
                    material, content, or any other MOXEY IP Assets may be
                    downloaded, modified, copied, displayed, transferred,
                    distributed, sold, published, broadcast or otherwise used
                    except as expressly stated either in such materials or in
                    this notice without the express prior written permission of
                    MOXEY (which MOXEY may or may not grant in its sole
                    discretion); (iii) User shall not remove, alter, cover or
                    obscure any copyright notices or other proprietary rights
                    notices of MOXEY or any other party placed on or embedded in
                    the MOXEY IP ASSETS and shall otherwise retain all such
                    notices on all copies of the same; and (iv) use of any of
                    the MOXEY IP Assets is prohibited unless User is an
                    authorized User in good standing. Unauthorized use is a
                    violation of copyright and other intellectual property
                    rights and is actionable under law.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    3.4 User agrees to keep strictly confidential all MOXEY IP
                    Assets that have not been made publically available by
                    MOXEY. User also acknowledges and agrees that the terms and
                    conditions of this provision shall survive the cancellation,
                    expiration or termination of this Agreement for any reason.
                  </span>
                </p>
                <p class="c0">
                  <span class="c7">4. Privacy.</span>
                </p>
                <p class="c0">
                  <span class="c8">
                    4.1 User agrees that: (i) if the User has any Content or any
                    login or password associated with this Site, then User is
                    solely responsible for maintaining the confidentiality of
                    the same; and (ii) if the User has any login or password
                    associated with this Site, then User (a) is solely
                    responsible for all uses of its login and password
                    regardless of whether these uses are authorized by User.
                    User, and (b) User will immediately notify MOXEY of any
                    unauthorized use of the User&rsquo;s login and password.
                  </span>
                  <span class="c7">
                    <br />
                    5. Indemnity.
                  </span>
                  <span class="c1">
                    &nbsp;User will indemnify and hold MOXEY, its parents,
                    subsidiaries, affiliates, officers, and employees, harmless,
                    including costs and attorneys&rsquo; fees, from any claim or
                    demand made by any third party due to or arising out of the
                    User&rsquo;s access to the Site, use of the Site, the
                    violation or other breach of this Agreement by the User, or
                    the infringement by the User, or any third party using the
                    User&rsquo;s account, of any intellectual property or other
                    right of any person or entity.
                  </span>
                </p>
                <p class="c0">
                  <span class="c7">6. Site Resources.</span>
                  <span class="c1">
                    &nbsp;The Site may provide a wide variety of information,
                    data, facts, and features (collectively, &ldquo;SITE
                    Resources&rdquo;) for the User&rsquo;s benefit. While MOXEY
                    endeavors to provide the most current and accurate Site
                    Resources as possible, the User acknowledges and agrees the
                    Site Resources may be general in nature and may not apply to
                    particular factual circumstances.
                  </span>
                </p>
                <p class="c0">
                  <span class="c3">
                    ALL SITE RESOURCES ARE PROVIDED &ldquo;AS IS&rdquo; AND SITE
                    RESOURCES AVAILABLE THROUGH THIS SITE MAY BE SUPERSEDED
                    AND/OR MAY INCLUDE INACCURACIES. ALSO, WHERE A DOCUMENT
                    CONTAINED AS A SITE RESOURCE IS OBTAINED FROM ANOTHER
                    SOURCE, THEN THE OTHER SOURCE (AND ITS WEBSITE) TAKES
                    PRECEDENCE. MOXEY MAY MAKE IMPROVEMENTS AND/OR CHANGES TO
                    THIS SITE, SITE RESOURCES AND ITS MOXEY SERVERS AT ANY TIME.
                  </span>
                  <span class="c7">
                    <br />
                    7. Links to Third Party Sites.
                  </span>
                  <span class="c1">
                    &nbsp;As a convenience to User, the Site may now, or in the
                    future, provide links to other Internet web sites that are
                    not owned by MOXEY, and are not under our control
                    (&ldquo;Third Party Websites&rdquo;). MOXEY does not control
                    the Third Party Websites and is not responsible for the
                    Content included in them including, without limitation, any
                    subsequent links contained within a linked web site, or any
                    changes or updates to a linked web site. Any reference from
                    the MOXEY Site to any entity, product, service or
                    information does not constitute an endorsement or
                    recommendation by MOXEY. No Third Party Website is
                    authorized to make any representations or warranties on our
                    behalf. Your visit to any Third Party Websites are subject
                    to the terms and conditions of such Third Party Websites,
                    and not this Site&rsquo;s Agreement. User should refer to
                    each Third Party Website&rsquo;s specific terms.
                  </span>
                </p>
                <p class="c0">
                  <span class="c7">
                    8. Disclaimer of Warranties; Disclaimer of Liability.
                  </span>
                </p>
                <p class="c0">
                  <span class="c4">8.1 </span>
                  <span class="c3">
                    MOXEY MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OR
                    ACCURACY OF THE SITE RESOURCES CONTAINED IN THIS SITE. ALL
                    SITE RESOURCES ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT
                    WARRANTY OF ANY KIND. MOXEY HEREBY DISCLAIMS ALL WARRANTIES
                    AND CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING
                    ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
                    MOXEY FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                    MOXEY DOES NOT WARRANT THAT THE SITE AND THE SITE RESOURCES
                    ON THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT THE
                    DEFECTS WILL BE CORRECTED, OR THAT THE MOXEY SERVER MAKING
                    THIS SITE AVAILABLE IS FREE OF COMPUTER VIRUSES OR OTHER
                    HARMFUL COMPONENTS.
                  </span>
                </p>
                <p class="c0">
                  <span class="c8">
                    8.2 MOXEY SHALL NOT be liable for any special, indirect,
                    INCIDENTAL OR consequential damages or any damages
                    whatsoever resulting from INCONVENIENCE, OR loss of use,
                    RESOURCES or profits, whether in an action of contract,
                    negligence or other TORTIOUS action, arising out of or in
                    connection with the use or performance of the site, or site
                    resources made available through this Site, or any third
                    party websites, EVEN IF MOXEY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES OR LOSSES.
                    <br />
                  </span>
                  <span class="c7">
                    <br />
                    9. Change in these Terms of Use.&nbsp;
                  </span>
                  <span class="c8">
                    Once User begins to use its Access Rights to the Site, MOXEY
                    may modify this Agreement at any time and User will
                    thereafter be bound by the version of this Agreement that is
                    in effect at the time User visits the Site. Any use of the
                    Site by User thereafter shall be deemed to constitute
                    acceptance by User of the amendments.
                  </span>
                  <span class="c7">
                    <br />
                  </span>
                  <span class="c13">
                    10. Termination. In the event User,&nbsp;
                  </span>
                  <span class="c9">
                    or anyone other using User&rsquo;s privileges, violate the
                    terms of this Agreement (as determined in MOXEY&rsquo;s
                    discretion), MOXEY reserves the right to take any action it
                    deems appropriate, including, but not limited to,
                    termination of this Agreement, including all Access Rights
                    (as such term is defined in Section 2.1 above). In addition
                    to any other right of MOXEY to terminate this Agreement,
                    MOXEY further reserves the right, without notice, at any
                    time, in its sole discretion, and for any reason, to
                    terminate this Agreement, including all Access Rights. MOXEY
                    is not required to provide mail or web page forwarding at
                    termination
                  </span>
                  <span class="c8">.</span>
                  <span class="c7">
                    <br />
                    11. Miscellaneous Provisions.
                  </span>
                </p>
                <p class="c0" id="h.gjdgxs">
                  <span class="c1">
                    11.1 Governing Law. This Agreement shall be construed and
                    controlled by the laws of Arab Republic of Egypt. The laws
                    of Egypt will govern any dispute arising from the terms of
                    this Agreement or a breach of this Agreement. User agrees to
                    the exclusive jurisdiction of Egyptian courts, regardless of
                    the applicable laws or jurisdiction the User may be subject
                    to.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    11.2 Notices. Except as otherwise expressly provided in this
                    Agreement, any communications between the parties, or
                    notices to be given hereunder, will be given in writing by
                    personal delivery, express courier, facsimile, postage
                    prepaid, to either User or MOXEY at the address or number
                    set forth in the signature block to this Agreement, or to
                    such other addresses or numbers as either Party may
                    hereafter indicate pursuant to this Section. Unless
                    otherwise provided in this Agreement, any communication or
                    notice so addressed and mailed will be deemed to be given
                    five (5) days after mailing. Unless otherwise provided in
                    this Agreement, any communication or notice delivered by (i)
                    facsimile will be deemed to be given when the transmitting
                    machine generates a receipt of a successful transmission of
                    the notice; or (ii) by email will be deemed to be given when
                    the email has been generated and sent by the sender. Unless
                    otherwise provided in this Agreement, any communication or
                    notice given by personal delivery will be deemed to be given
                    immediately upon such delivery, provided such delivery is
                    made to the person indicated below.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    11.2 Remedies. User acknowledges that monetary damages may
                    not be a sufficient remedy for unauthorized use of
                    MOXEY&rsquo;s Site, and therefore User agrees that MOXEY
                    shall be entitled, without waiving any other rights or
                    remedies, to such injunctive or equitable relief as may be
                    deemed proper by a court or arbitration panel of competent
                    jurisdiction without necessity of posting a bond and without
                    having to plead and prove lack of an adequate remedy at law.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    11.3 Attorney Fees. If any suit or action is filed by any
                    party to enforce this Agreement or otherwise with respect to
                    the subject matter of this Agreement, the prevailing party
                    shall be entitled to recover reasonable attorney fees
                    incurred in preparation or in prosecution or defense of such
                    suit or action as fixed by the trial court and, if any
                    appeal is taken from the decision of the trial court,
                    reasonable attorney fees as fixed by the appellate court.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    11.4 Binding Effect; No Assignment by User; Permissible
                    Assignment by MOXEY. This Agreement shall be binding upon
                    and inure to the benefit of each party&rsquo;s respective
                    successors and lawful assigns; provided, however, that User
                    may not assign this Agreement, in whole or in part. Any
                    purported assignment in violation of this Section shall be
                    void. MOXEY shall have the right to assign this Agreement,
                    or any part of it, in its sole discretion to any party, and
                    all covenants and agreements hereunder shall inure to the
                    benefit of and be enforceable by such successors and
                    assigns.
                  </span>
                </p>
                <p class="c0">
                  <span class="c1">
                    11.5 Entire Agreement; Modification; Waiver. This Agreement,
                    including each Exhibit attached hereto, constitutes the
                    entire agreement between the parties with respect to the
                    subject matter hereof. It shall not be modified except by a
                    written agreement dated subsequent to the date of this
                    Agreement and signed by both parties. None of the provisions
                    of this Agreement shall be deemed to have been waived by any
                    act or acquiescence on the part of MOXEY, User, their
                    agents, or employees, but only by an instrument in writing
                    signed by an authorized employee of MOXEY and User. No
                    waiver of any provision of this Agreement shall constitute a
                    waiver of any other provision(s) or of the same provision on
                    another occasion. If any part of this Agreement is
                    determined to be invalid or unenforceable pursuant to
                    applicable law, then the remainder of the Agreement shall
                    continue in effect.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyComponent;
