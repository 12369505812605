import "./hero.style.scss";
import LeftImg2 from "./imgs/sub-hero/left2.png";
import LeftImg1 from "./imgs/sub-hero/left1.png";
import CenterImg from "./imgs/sub-hero/center.png";
import RightImg1 from "./imgs/sub-hero/right1.png";
import RightImg2 from "./imgs/sub-hero/right2.png";

export default function HeroSubSection() {
  return (
    <div className="moxey-hero-sub">
      <h1
        className="moxey-hero-sub-header"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Seamless. Fast. Hassle-free
      </h1>
      <div className="moxey-hero-sub-content">
        <img
          className="mobile"
          src={LeftImg2}
          data-aos="fade-left"
          data-aos-delay="500"
        />
        <img
          className="mobile"
          src={LeftImg1}
          data-aos="fade-left"
          data-aos-delay="300"
        />
        <img
          className="mobile"
          src={CenterImg}
          data-aos="zoom-in"
          data-aos-delay="100"
        />
        <img
          className="mobile"
          src={RightImg1}
          data-aos="fade-right"
          data-aos-delay="300"
        />
        <img
          className="mobile"
          src={RightImg2}
          data-aos="fade-right"
          data-aos-delay="500"
        />
      </div>
    </div>
  );
}
