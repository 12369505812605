import HeroSection from "../../components/Blogs/blog-hero.component";
import BlogLists from "../../components/Blogs/blog-list.component";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "Blogs - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

function Blogs() {
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/blogs`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/blogs`} />
      </Helmet>
      <HeroSection />
      <BlogLists />
    </div>
  );
}

export default Blogs;
