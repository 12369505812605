import { Outlet } from "react-router-dom";

// importing components
import AppNavbar from "../../components/common/Navbar/navbar.component";
import AppFooter from "../../components/common/Footer/footer.component";
import { HelmetProvider } from "react-helmet-async";
import "./app.style.scss";

function AppLayout() {
  return (
    <HelmetProvider>
      <div className="App">
        <AppNavbar />
        <Outlet />
        <AppFooter />
      </div>
    </HelmetProvider>
  );
}

export default AppLayout;
