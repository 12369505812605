import { Link } from "react-router-dom";
import { HashLink as HLink } from "react-router-hash-link";
import { useEffect, useState } from "react";

import "./navbar.style.scss";
import NextIcon from "./next_icon.svg";

function NavbarDropdownItem({ item, index, openBanner, closeBanner }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <li
      className="nav-item dropdown"
      onFocus={() => setIsOpen(!isOpen)}
      onBlur={() => setIsOpen(!isOpen)}
    >
      <a
        className="nav-link"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={openBanner}
      >
        <span className="nav-text text-capitalize">{item.title}</span>

        <img
          className={`nav-icon ms-2 mb-1 ${isOpen ? "open" : null}`}
          src={NextIcon}
          alt="next icon"
        />
      </a>
      <ul className="dropdown-menu bg-transparent shadow-0 border-0">
        <ul className="dropdown-list p-0">
          {item.items.map((i, index) => {
            return (
              <li key={index} className="dropdown-list-item text-capitalize">
                <HLink
                  to={`/${i.id}`}
                  onClick={closeBanner}
                  className="dropdown-item-text text-decoration-none ps-2"
                >
                  {i.name}
                </HLink>
              </li>
            );
          })}
        </ul>
      </ul>
    </li>
  );
}

export default NavbarDropdownItem;
