import "./support.style.scss";

import SupportImg from "./imgs/support_section_img.png";
import PhoneIcon from "./imgs/phone-icon.png";
import MailIcon from "./imgs/mail-icon.png";

const serviceFeatures = [
  "Easy and instant payments",
  "Top success rate",
  "Instant settlements",
  "Real-time reporting and insights",
  "Frictionless customer experience",
];

function SupportSection() {
  return (
    <div className="moxey-support">
      <div className="moxey-support-bg"></div>
      <div className="container">
        <h1 className="moxey-support-headline w-50" data-aos="fade-up">
          Meet all your Fintech requirements
        </h1>
        <div className="row">
          <div className="col-lg-5">
            <p className="moxey-support-line-1" data-aos="fade-in">
              Whether you’re a transporter, retail merchant, or logistics
              manager, MoXey provides you with one platform for all your fintech
              needs.
            </p>
            <ul className="moxey-support-list">
              {serviceFeatures.map((i, index) => (
                <li
                  className="moxey-support-feature"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  key={index}
                >
                  {i}
                </li>
              ))}
            </ul>
            <div className="moxey-support-action d-flex mt-4">
              <a
                href="mailto:support@moxey.ai"
                className="btn btn-light shadow rounded-pill px-3"
              >
                <img src={MailIcon} className="icon" />
                <span className="text-success ms-2">support@moxey.ai</span>
              </a>
              <a
                href="tel:600524642"
                className="btn btn-light shadow rounded-pill px-3"
              >
                <img src={PhoneIcon} className="icon" />
                <span className="text-success ms-2">+971 522774554</span>
              </a>
            </div>
          </div>
          <div className="col-lg-7 d-flex justify-content-start">
            <img
              className="moxey-support-img img-fluid"
              src={SupportImg}
              alt="Meet all your Fintech requirements"
              data-aos="fade-left"
              data-aos-delay="800"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportSection;
