import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_06.png";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Benefits of Digital Payments for Fleet Management",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[7].meta_keywords} />
        <meta name="description" content={META_BLOGS[7].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[7].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("7")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[7].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("7")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>

        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>March, 01 2023 - 3 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            The advantages of using digital payments for fleet management are
            enormous, and they are becoming a popular way of payment in today's
            world. We'll look at the benefits of digital payments for fleet
            management and how they can help organisations streamline their
            operations:
          </p>
          <ol>
            <li>
              Increased Efficiency: Fleet managers can now pay for goods and
              services electronically with digital payments, which eliminates
              the need for manual payment procedures. As a result, fleet
              managers can concentrate on more crucial responsibilities, like
              managing their fleet and increasing efficiency, instead of the
              time-consuming chore of managing paper receipts and invoices.
            </li>
            <br />
            <li>
              Cost Savings: By eliminating the need for checks, stamps, and
              envelopes, digital payments can help fleet managers save money.
              Electronic payments also reduce the costs of lost or stolen
              checks, as well as the cost of replacing them. Furthermore,
              digital payments can aid in the reduction of administrative costs
              associated with traditional payment methods.
            </li>
            <br />
            <li>
              Improved Transparency: Because every transaction is recorded and
              documented, digital payments provide increased transparency. Fleet
              managers can easily track expenses, identify discrepancies, and
              analyze spending patterns. This provides valuable insights into
              fleet expenses and can assist businesses in identifying areas
              where they can cut costs or optimize their spending.
            </li>
            <br />
            <li>
              Enhanced Security: Digital payments provide enhanced security and
              reduce the risk of fraud. Traditional payment methods, such as
              checks, are easily forged or stolen, whereas digital payments
              provide multiple layers of protection against unauthorized access.
              Furthermore, digital payments provide real-time fraud monitoring,
              which assists businesses in detecting and preventing fraudulent
              activity.
            </li>
            <br />
            <li>
              Greater Convenience: Digital payments offer greater convenience
              for fleet managers, who can make payments from anywhere, at any
              time. Fleet managers will no longer need to visit banks or cash
              centers to complete payment transactions. This is especially
              useful for businesses with multiple locations because it allows
              them to manage payments remotely while avoiding the logistical
              challenges associated with traditional payment methods.
            </li>
            <br />
            <li>
              Enhanced Cash Flow Management: By automating payment processes,
              digital payments allow fleet managers to better manage their cash
              flow. This can assist businesses in avoiding late payments and
              penalties while also improving their credit ratings. Furthermore,
              digital payments offer greater flexibility and allow businesses to
              adjust payment schedules as needed.
            </li>
            <br />
          </ol>
          <p>
            Finally, digital payments provide numerous benefits for fleet
            management, including increased efficiency, cost savings, improved
            transparency, enhanced security, greater convenience, and improved
            cash flow management.
          </p>
          <p>
            One-stop payment solution <b>MoXey</b> offers digital payment for fleet
            management, allowing businesses to streamline their fleet management
            operations, reduce costs, and improve their overall financial
            performance. With increasing demand, it is critical for businesses
            to implement these methods in order to remain competitive in today's
            market.
          </p>
          <p>
            Switch to <b>MoXey</b> to automate salary payments and make digital payments to employees.
          </p>
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
