export const faq = [
  {
    query: "How can I register on MoXey? ",
    response:
      "Register on MoXey through self sign-up using MoXey Pay App or assisted sign-up where on-ground MoXey executives helps you to register on MoXey Pay platform. Your transporter also has the ability to sign you up on MoXey Pay platform.\nYour sign-up requires you to share basic KYC information as required by the regulatory authority.",
    category: "driver",
  },
  {
    query: "Where can I collect my Card?",
    response:
      "You can collect your MoXey Pay card from the designated MoXey Center. This information is collected during the signup process.",
    category: "driver",
  },
  {
    query: "I forgot my MPIN",
    response:
      'You can reset your MPIN by clicking on the "Forgot MPIN" option on the MoXey Pay App\'s login screen and following the required steps.',
    category: "driver",
  },
  {
    query: "How do I change my MPIN?",
    response:
      'You can change your MPIN by clicking on the "Forgot MPIN" option on the MoXey Pay App\'s login screen or by accessing the "Reset MPIN" option inside the profile option of MoXey Pay App.',
    category: "driver",
  },
  {
    query: "How to update phone number?",
    response:
      "Please reach out to MoXey customer service or raise a update KYC reqeust from MoXey Pay App.",
    category: "driver",
  },
  {
    query: "KYC rejected what can I do?",
    response:
      "You will need to re-inititate your MoXey Pay card application by filling in the necessary details and attaching clear images of the required Proof of Identity documents (National ID, Passport).",
    category: "driver",
  },
  {
    query: "How can I change my card PIN? ",
    response:
      "Currently card PIN can be changed only by visiting the Bank centers or Bank ATMs.\n",
    category: "driver",
  },
  {
    query: "I lost my card what should I do?",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app - this would prevent your lost card from being fradulently used.",
    category: "driver",
  },
  {
    query: "I want to block my card? ",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app.",
    category: "driver",
  },
  {
    query: "Can I have two cards?",
    response: "No, an individual can only be issued one MoXey Pay card.",
    category: "driver",
  },
  {
    query: "I want to surrender my card?",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app. Once blocked you would not be able to use your card.",
    category: "driver",
  },
  {
    query: "Can I link my existing card with MoXey Pay?",
    response:
      "Currently we don't support linking of external cards with MoXey Pay account.",
    category: "driver",
  },
  {
    query: "Can I withdraw cash from my card? ",
    response: "Yes, you can withdraw cash from any ATM using your MoXey card.",
    category: "driver",
  },
  {
    query: "Can I receive payments from multiple transporters? ",
    response:
      "Yes, you can receive payments from multiple transporters on your MoXey Pay card.",
    category: "driver",
  },
  {
    query: "Can I change the location of my card delivery?",
    response:
      "Please reach out to MoXey customer service and register your request for change in card delivery address.",
    category: "driver",
  },
  {
    query: "Can I deposit money in my MoXey Card? ",
    response:
      "No, you can only recieve money into your MoXey card from transporters.",
    category: "driver",
  },
  {
    query: "Who all can deposit money in my MoXey Card?",
    response: "Transporters can deposit money into your MoXey card.",
    category: "driver",
  },
  {
    query: "Can I transfer money through my MoXey card to my friends? ",
    response:
      "No, currently it is not possible to transfer money to your friends and contacts from your MoXey card.",
    category: "driver",
  },
  {
    query: "What all International locations I can use my card at? ",
    response:
      "MoXey card supports international transactions and you can use your card at any international location (e.g. merchant shops). For transactions done at international locations foreign exchange fee would be applicable .",
    category: "driver",
  },
  {
    query: "How much can I accept using MoXey? ",
    response:
      "There is not limit on the amount that you can receive on your MoXey card.",
    category: "driver",
  },
  {
    query: "Will I be receiving notifications for my transaction?",
    response:
      "Yes, you would be receiving SMS notifications for any credit and debit transactions that are done on your MoXey card.",
    category: "driver",
  },
  {
    query: "Which ATM can I withdraw cash from? ",
    response: "You can withdraw cash from any ATM.",
    category: "driver",
  },
  {
    query:
      "I am cross border driver, can I withdraw money in other country also? ",
    response:
      "Yes, you can withdraw money in other countries. Foreign exchange fees would be applicable for such transactions.",
    category: "driver",
  },
  {
    query: "Transaction failed but money got deducted from my MoXey card? ",
    response:
      "Any amount deducted from your MoXey card for a failed transaction would be credited back within 7-10 working days.",
    category: "driver",
  },
  {
    query:
      "What happens if a double payment is deducted against a transaction from my card? ",
    response:
      "The double payment would be reversed and credited back to your MoXey card within 8 to10 working days. Please reach out to MoXey customer support to report any transaction issue.",
    category: "driver",
  },
  {
    query: "How can I submit proof of my expenses to the transporter?",
    category: "driver",
  },
  {
    query: "What are the different charges on this card? ",
    response:
      "MoXey card is free and doesn't have any monthly charges associated with it. However, your ATM withdrawals will be chargeable at AED 2, and for any cross-border transactions you would be charged foreign exchange charges.",
    category: "driver",
  },
  {
    query: "Do I have to pay anything for card issuance ?",
    response:
      "No, MoXey card is free, there are no issuance charges associated with your MoXey Pay card.",
    category: "driver",
  },
  {
    query: "What are the charges for cash withdrawal?",
    response: "AED 2 will be charged for any cash withdrawal.",
    category: "driver",
  },
  {
    query: "Is there any card maintenance charges?",
    response:
      "No, we don't charge anything as monthly maintenance charges, it's free.",
    category: "driver",
  },
  {
    query: "Will I be charged for using the card at an international location?",
    response:
      "Yes, you would be charged foreign exchange fees for your transactions at international locations.",
    category: "driver",
  },
  {
    query: "What are the charges for purchasing at merchant location?",
    response:
      "There are no charges for using your card at any merchant location, you would only be required to pay the amount shown in your bill.",
    category: "driver",
  },
  {
    query: "What are the charges for making online purchases?",
    response:
      "There are no charges for using your card for online purchases, you would only be required to pay the amount shown in your bill.",
    category: "driver",
  },
  {
    query:
      "My MoXey App showed a discount, however on reaching that merchant he refused to give any discount?",
    response:
      "Discounts and offers are provided at merchant's discretion, kindly report any offer related discrepency to MoXey customer support.",
    category: "driver",
  },
  {
    query: "How can I check offers?",
    response:
      "You can go to Offers section within MoXey app and see all the offers listed for the different products.",
    category: "driver",
  },
  {
    query:
      "I have not received my cash back, How many days it takes to receive the cash back?",
    response: "Cashback is credited back to your MoXey card within 72 hours.",
    category: "driver",
  },
  {
    query: "How can I check if a merchant has any offers?",
    response:
      "You can visit the offers section in the MoXey app and check the offers. The search function allows you to search for a particular merchant and check the specific products and offers.",
    category: "driver",
  },
  {
    query: "I have not received my referral bonus. ",
    response:
      "Referral bonus will be credited to your MoXey account once your referee signs up for MoXey and is issued with a MoXey card.",
    category: "driver",
  },
  {
    query: "Where can I contact MoXey?",
    response:
      "You can reach out to our customer support by writing in at support@moxey.ai or call at +971-600533355",
    category: "driver",
  },
  {
    query: "Which bank card will I be issued ?",
    response: "Your MoXey card is issued in association with Arab Bank.",
    category: "driver",
  },
  {
    query: "Are you available on Huwaei play store? ",
    category: "driver",
  },
  {
    query: "Can I search nearby merchant through MoXey. ",
    response:
      "Yes, you can go to Offers section within MoXey app and search for the nearby merchants.",
    category: "driver",
  },
  {
    query: "How much time it will take for me to receive money",
    response: "You would receive your money instantly.",
    category: "driver",
  },
  {
    query: "Do I get a bank account associated with my MoXey Card? ",
    response:
      "No, currently there is no bank account associated with MoXey card.",
    category: "driver",
  },
  {
    query: "How can register on MoXey ? ",
    response:
      "Our on-ground MoXey executives help you to register on MoXey Pay platform. Sign up is an easy process and will require you to share your KYB information (Business registration, trade license, etc.) for regulatory compliance.",
    category: "transporter",
  },
  {
    query: "KYC is rejected what can I do?",
    response:
      "Please reach out to MoXey customer support to re-initiate the onbaording with valid KYB details.",
    category: "transporter",
  },
  {
    query: "How to update the KYC details ?",
    response:
      "Please reach out to MoXey customer support and will help you with any KYB/contact details update.",
    category: "transporter",
  },
  {
    query: "I forgot my password ? ",
    response:
      'You can request for reseting you account password by clicking on "Forgot Password" link provided on the login page.',
    category: "transporter",
  },
  {
    query: "How do i change my password?",
    response:
      'Click on the "Forgot Password" page provided on the login page, share your user ID and you would recieve an email with the required steps to rest your password.\nAlternately, if you are logged in your MoXey account then go to "Settings" page and click on "Security" tab to change your password.',
    category: "transporter",
  },
  {
    query: "I don't remember my user ID?",
    response:
      "Please reach out to MoXey customer support and will help you retrive your login information.",
    category: "transporter",
  },
  {
    query: "How to update phone number ?",
    response:
      "Please reach out to MoXey customer support and will help you with any KYB/contact details update.",
    category: "transporter",
  },
  {
    query: "How do i add drivers ? ",
    response:
      'You can add drivers in 3 easy steps - \n1. Login to your MoXey account\n2. Visit the "Drivers" page and click on "Add New Driver"\n3. Fill in driver\'s information along with KYC details and hit "Send"\n\nThe submitted information will be reviewed by MoXey and post successful KYC verification MoXey card will be issued to the driver.',
    category: "transporter",
  },
  {
    query: "How can i update driver details ? ",
    response:
      "You won't be able to modify the driver details once submitted from your side, please reach out to MoXey customer service for any update required for submitted driver information.",
    category: "transporter",
  },
  {
    query: "Can i create a maker checker system?",
    response:
      "Yes, MoXey provides the capability of implementing maker-checker functionality. You will be able to create users with specific roles and using this you can leverage the maker-checker functionality.",
    category: "transporter",
  },
  {
    query: "How do I add my branches? ",
    category: "transporter",
  },
  {
    query: "Can i mark driver and Broker separately in MoXey? ",
    response:
      'Yes, you need to select the option to onboard an user as a "Driver" or "Driver & Broker". Once the user is successfully onboarded you can transfer funds to drivers and brokers working with you.',
    category: "transporter",
  },
  {
    query: "What benefits i have as transporter in MoXey ? ",
    response:
      "MoXey is a digital financial payments solution for land freight industry and some of the benefits are-\n1. Reduced cash leakages\n2. Easy payout system to pay your drivers digitally and instantly\n3. Fast, secure and easy to use\n4. Digitzed transaction records\n5. Reduce your operational costs",
    category: "transporter",
  },
  {
    query: "How can i Top up my MoXey account ?",
    response:
      "You would need to transfer money to MoXey's bank account and raise a prefund request from MoXey's portal providing the transaction details. Once your prefund request your verified, you would be provided credit in your MoXey account.",
    category: "transporter",
  },
  {
    query: "How can i check balance of my account ? ",
    response:
      "You can login to your MoXey account and check the Available Prefund balance in the dashboard.",
    category: "transporter",
  },
  {
    query: "How can i make payment to the drivers ? ",
    response:
      'Transfer funds to your drivers in 3 easy steps\n1. Login to your MoXey account\n2. Visit the "Cards" page and click on "Actions" against the driver name you want to transfer the funds to\n3. Select "Transfer Fund", fill in the required details and hit "Send"',
    category: "transporter",
  },
  {
    query: "How can I make payments to the Brokers? ",
    response:
      'Transfer funds to your drivers in 3 easy steps\n1. Login to your MoXey account\n2. Visit the "Cards" page and click on "Actions" against the driver name you want to transfer the funds to\n3. Select "Transfer Fund", fill in the required details and hit "Send"',
    category: "transporter",
  },
  {
    query: "I transferred money to the wrong driver by mistake. ",
    response:
      "As per regulations MoXey cannot debit a driver's card, you will have to adjust the excess payment in your future transactions with the driver.",
    category: "transporter",
  },
  {
    query: "I have salaried drivers can i pay them also through MoXey ? ",
    response:
      "Yes, you can pay any driver through MoXey - contractual as well as salaried. Please onboard the driver on MoXey and submit the KYC details, once the KYC is approved, the driver will be issued a MoXey card and you will be able to transfer funds to this MoXey card.",
    category: "transporter",
  },
  {
    query: "Can i pay other transporters through MoXey ? ",
    response:
      "You can pay other transporters through MoXey provided they are onboarded on MoXey platform.",
    category: "transporter",
  },
  {
    query: "I transferred excess money to the driver by mistake ? ",
    response:
      "As per regulations MoXey cannot debit a driver's card, you will have to adjust the excess payment in your future transactions with the driver.",
    category: "transporter",
  },
  {
    query:
      "Can i collect proof of advances paid to the driver through MoXey ? ",
    response:
      'You can always check the transfer history and see all the electronic transfers you have made to the drivers. After logging to your MoXey account, go to "Reporting" page and check the "Card Load" report, here you will be able to check all the card loads that you have requested and their status. Alternately you can also check the transfer history for a particular driver in the "Cards" page by clicking on "Action" button against a particular driver name and selecting "View Transfer History".',
    category: "transporter",
  },
  {
    query: "I am not receiving notifications for my transactions ? ",
    response:
      "You would be recieving SMS for all your transactions on your registered mobile number as this is a default setting. Please reach out to MoXey customer support incase you are not receiving any SMS.",
    category: "transporter",
  },
  {
    query: "What charges i need to pay to have MoXey ? ",
    response:
      "Your platform usage charges are mentioned in your agreement with MoXey, you can also reach out to your Account Manager to check the charges.",
    category: "transporter",
  },
  {
    query:
      "Can as a transporter can I directly avail benefits from the Merchant partner. ",
    response:
      "Currently the offers from a merchant partner can be availed by the drivers with MoXey card.",
    category: "transporter",
  },
  {
    query: "Can i issue Co-branded cards through MoXey for my drivers? ",
    response: "No, your drivers would be issued MoXey branded cards.",
    category: "transporter",
  },
  {
    query: "Can i integrate moxey with my existing system ? ",
    response:
      "Yes you can integrate MoXey with your existing system. Reach out to our customer care team to know more. ",
    category: "transporter",
  },
  {
    query:
      "Can I get back the money transferred to my prefund account in MoXey?",
    response:
      "If the cards are loaded with the transferred amount, then as per regulation the money cant be debited from the card. If the cards are not loaded, you can contact moxey support at support@moxey.ai / 600533355 and request for transfer back. The money will credited back to the origination account within 14 working days",
    category: "transporter",
  },
  {
    query: "How can I register on MoXey? ",
    response:
      "Register on MoXey through self-sign-up using MoXey Pay App or assisted sign-up where on-ground MoXey executives help you to register on MoXey Pay platform. Your transporter also has the ability to sign you up on the MoXey Pay platform. Your sign-up requires you to share basic KYC information as required by the regulatory authority.",
    category: "partner",
  },
  {
    query: "Where can I collect my Card?",
    response:
      "You can collect your MoXey Pay card from the designated MoXey Center. This information is collected during the signup process.",
    category: "partner",
  },
  {
    query: "I forgot my MPIN? ",
    response:
      'You can reset your MPIN by clicking on the "Forgot MPIN" option on the MoXey Pay App\'s login screen and following the required steps.',
    category: "partner",
  },
  {
    query: "How do i change my MPIN?",
    response:
      'You can change your MPIN by clicking on the "Forgot MPIN" option on the MoXey Pay App\'s login screen or by accessing the "Reset MPIN" option inside the profile option of MoXey Pay App.',
    category: "partner",
  },
  {
    query: "How to update phone number?",
    response:
      "Please reach out to MoXey customer service or raise a update KYC reqeust from MoXey Pay App.",
    category: "partner",
  },
  {
    query: "My KYC was rejected what can I do?",
    response:
      "You will need to re-inititate your MoXey Pay card application by filling in the necessary details and attaching clear images of the required Proof of Identity documents (National ID, Passport).",
    category: "partner",
  },
  {
    query: "How can I change my card PIN? ",
    response:
      "Currently card PIN can be changed only by visiting the Bank centers or Bank ATMs.\n",
    category: "partner",
  },
  {
    query: "I lost my card what should i do?",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app - this would prevent your lost card from being fradulently used.",
    category: "partner",
  },
  {
    query: "I want to block my card? ",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app.",
    category: "partner",
  },
  {
    query: "Can I have two cards?",
    response: "No, an individual can only be issued one MoXey Pay card.",
    category: "partner",
  },
  {
    query: "I want to surrender my card?",
    response:
      "Please reach out to MoXey customer service or raise a card block request using your MoXey Pay app. Once blocked you would not be able to use your card.",
    category: "partner",
  },
  {
    query: "Can I link my existing card with MoXey Pay?",
    response:
      "Currently we don't support linking of external cards with MoXey Pay account.",
    category: "partner",
  },
  {
    query: "Can i withdraw cash from my card? ",
    response: "Yes, you can withdraw cash from any ATM using your MoXey card.",
    category: "partner",
  },
  {
    query: "Can i receive payments from multiple transporters? ",
    response:
      "Yes, you can receive payments from multiple transporters. Your recieved payments are credited to your MoXey wallet and you can use this amount to load your MoXey card.",
    category: "partner",
  },
  {
    query: "Can I change the location of my card delivery?",
    response:
      "Please reach out to MoXey customer service and register your request for change in card delivery address.",
    category: "partner",
  },
  {
    query: "Can i deposit money in my MoXey Card? ",
    response:
      "Yes, you can deposit money in your Moxey card. You will need to have balance in your MoXey wallet which will be used to load your MoXey card.",
    category: "partner",
  },
  {
    query: "Who all can deposit money in my MoXey Card?",
    response:
      "Yes, you can deposit money in your Moxey card. You will need to have balance in your MoXey wallet which will be used to load your MoXey card.",
    category: "partner",
  },
  {
    query: "Can I transfer money through my MoXey card to my friends? ",
    response:
      'No, currently it is not possible to transfer money to your friends and contacts from your MoXey card. You can use your MoXey wallet to transfer money to your friends on their MoXey cards in 4 easy steps.\nGo to Card Load section, click on the "+" sign, fill in the card load details and send your card load request!',
    category: "partner",
  },
  {
    query: "What all International locations i can use my card for? ",
    response:
      "MoXey card supports international transactions and you can use your card at any international location (e.g. merchant shops). For transactions done at international locations foreign exchange fee would be applicable .",
    category: "partner",
  },
  {
    query: "Which ATM can i withdraw cash from? ",
    response: "You can withdraw cash from any ATM.",
    category: "partner",
  },
  {
    query: "Can I withdraw money from another country also?",
    response:
      "Yes, you can withdraw money in other countries. Foreign exchange fees would be applicable for such transactions.",
    category: "partner",
  },
  {
    query: "How much can I accept using MoXey? ",
    response:
      "There is not limit on the amount that you can receive on your MoXey card.",
    category: "partner",
  },
  {
    query: "Will I be receiving notifications for my transaction?",
    response:
      "Yes, you would be receiving SMS notifications for any credit and debit transactions that are done on your MoXey card.",
    category: "partner",
  },
  {
    query: "Which ATM can i withdraw cash from? ",
    response: "You can withdraw cash from any ATM.",
    category: "partner",
  },
  {
    query:
      "I am cross border driver, can I withdraw money in other country also? ",
    response:
      "Yes, you can withdraw money in other countries. Foreign exchange fees would be applicable for such transactions.",
    category: "partner",
  },
  {
    query: "Transaction failed but money got deducted from my MoXey card? ",
    response:
      "Any amount deducted from your MoXey card for a failed transaction would be credited back within 8-10 working days.",
    category: "partner",
  },
  {
    query:
      "What happens if a double payment is deducted against a transaction from my card? ",
    response:
      "The double payment would be reversed and credited back to your MoXey card within 8-10 working days. Please reach out to MoXey customer support to report any transaction issues.",
    category: "partner",
  },
  {
    query: "How can i submit proof of my expenses to the transporter?",
    category: "partner",
  },
  {
    query: "I am not receiving notifications for my transactions ? ",
    response:
      "SMS for transactions is enabled for all MoXey users. In case you are not receiving your transaction SMS then please reach out to MoXey customer support for assistance.",
    category: "partner",
  },
  {
    query: "What are the different charges on this card? ",
    response:
      "MoXey card is free and doesn't have any monthly charges associated with it. You would be charged AED 2 for cash withdrawal from ATMs and for any cross-border transactions you would be charged foreign exchange charges.",
    category: "partner",
  },
  {
    query: "Is there card issuance charges?",
    response:
      "No, there are no issuance charges associated with your MoXey Pay card.",
    category: "partner",
  },
  {
    query: "What are the charges for cash withdrawal?",
    response: "AED 2 would be charged for any cash withdrawal.",
    category: "partner",
  },
  {
    query: "Is there any card maintenance charges?",
    response:
      "MoXey card is free and doesn't have any monthly charges associated with it.",
    category: "partner",
  },
  {
    query: "Will I be charged for using the card at an international location?",
    response:
      "Yes, you would be charged foreign exchange fees for making transactions at international locations.",
    category: "partner",
  },
  {
    query: "what are the charges for purchasing at merchant location?",
    response:
      "There are no charges for using your card at any merchant location, you would only be required to pay the amount shown in your bill.",
    category: "partner",
  },
  {
    query: "What are the charges for making online purchases?",
    response:
      "There are no charges for using your card for online purchases, you would only be required to pay the amount shown in your bill.",
    category: "partner",
  },
  {
    query:
      "My application showed a discount, however on reaching that merchant he refused to give any discount ? ",
    response:
      "Discounts and offers are provided at merchant's discretion, kindly report any offer related discrepency to MoXey customer support.",
    category: "partner",
  },
  {
    query: "How can i check offers?",
    response:
      "You can go to Offers section within MoXey app and see all the offers listed for the different products.",
    category: "partner",
  },
  {
    query:
      "I have not received my cash back, How many days it takes to receive the cash back?",
    response: "Cashback is credited back to your MoXey card within 72 hours.",
    category: "partner",
  },
  {
    query: "How can i check if a merchant has any offers?",
    response:
      "You can visit the offers section in the MoXey app and check the offers. The search function allows you to search for a particular merchant and check the specific products and offers.",
    category: "partner",
  },
  {
    query: "I have not received my referral bonus. ",
    response:
      "Referral bonus will be credited to your MoXey account once your referee signs up for MoXey and is issued with a MoXey card.",
    category: "partner",
  },
  {
    query: "Where can i contact MoXey?",
    response:
      "You can reach out to our customer support by writing in at support@moxey.ai or call at +971-600533355",
    category: "partner",
  },
  {
    query: "Which bank card will i be issued ?",
    response: "Your MoXey card is issued in association with Arab Bank.",
    category: "partner",
  },
  {
    query: "Are you available on Huwaei play store? ",
    category: "partner",
  },
  {
    query: "Can I search nearby merchant through MoXey. ",
    response:
      "Yes, you can go to Offers section within MoXey app and search for the nearby merchants.",
    category: "partner",
  },
  {
    query: "How much time it will take for me to receive money",
    response: "You would receive your money instantly.",
    category: "partner",
  },
  {
    query: "Do I get a bank account associated with my MoXey Card? ",
    response:
      "No, currently there is no bank account associated with MoXey card.",
    category: "partner",
  },
  {
    query: "How can i register on MoXey ?",
    response:
      "Our on-ground MoXey executives help you to register on MoXey Pay platform. Sign up is an easy process and will require you to share your KYB information (Business registration, trade license, etc.) for regulatory compliance.",
    category: "merchant",
  },
  {
    query: "I want to update my Business Details ?",
    response:
      "Please reach out to MoXey customer support and will help you with any KYB/contact details update.",
    category: "merchant",
  },
  {
    query: "I want to Change my Password ?",
    category: "merchant",
  },
  {
    query: "I want to change my app Language ",
    category: "merchant",
  },
  {
    query: "I want to update my display name ? ",
    category: "merchant",
  },
  {
    query: "How can I check my settlements? ",
    category: "merchant",
  },
  {
    query: "What are the charges i need to pay to order a MoXey POS device? ",
    category: "merchant",
  },
  {
    query: "How can is MoXey POS ?",
    category: "merchant",
  },
  {
    query: "What are the benefits of MoXey POS? ",
    category: "merchant",
  },
  {
    query: "How can update my product Catalouge ?",
    category: "merchant",
  },
  {
    query: "How can i run reward son MoXey ? ",
    category: "merchant",
  },
  {
    query: "What are all  the charges i need to pay as merchant on MoXey ",
    category: "merchant",
  },
  {
    query: "How can i add my branches on MoXey ?",
    category: "merchant",
  },
  {
    query: "How can I add a Franchise on MoXey? ",
    category: "merchant",
  },
  {
    query: "What are the charges levied on my merchant account ? ",
    category: "merchant",
  },
  {
    query: "How can i download my settlement reports? ",
    category: "merchant",
  },
  {
    query: "How can I download my transaction report? ",
    category: "merchant",
  },
  {
    query: "How will MoXey settle my account?",
    category: "merchant",
  },
  {
    query: "How can I download my transaction report? ",
    category: "merchant",
  },
  {
    query: "How will MoXey settle my account?",
    category: "merchant",
  },
];
