import "./blogs.style.scss";
import PrimaryActionButton from "../ui/Buttons/primary-button.component";
import { Link } from "react-router-dom";

import blogThumbnail1 from "./imgs/blogs/Blogs_card_01.png";
import blogThumbnail2 from "./imgs/blogs/Blogs_card_02.png";
import blogThumbnail3 from "./imgs/blogs/Blogs_card_03.png";
import blogThumbnail4 from "./imgs/blogs/Blogs_card_04.png";
import blogThumbnail5 from "./imgs/blogs/Blogs_card_05.png";
import blogThumbnail6 from "./imgs/blogs/Blogs_card_06.png";
import blogThumbnail10 from "./imgs/blogs/Blogs_card_10.jpeg";
import blogThumbnail11 from "./imgs/blogs/Blogs_card_11.jpeg";
import blogThumbnail12 from "./imgs/blogs/Blogs_card_12.jpeg";

import shareIcon from "./imgs/share_icon.png";
import bookmarkIcon from "./imgs/bookmark_icon.png";

import { generateBlogRoute } from "../../routes";

export const blogs = [
  {
    title: "Save Big with Fleet Cards",
    thumbnail: blogThumbnail1,
    short_desc:
      "You can manage your fleet hassle free, with digital records track real-time fund movements, see every transaction you make during the freight move and have actionable insights",
    date: "November, 01 2022",
    read: "5 min read",
  },
  {
    title: "Fintech Which Is Transforming Freight Like Never Before",
    thumbnail: blogThumbnail2,
    short_desc:
      "Land freight industry relies on a process that is astonishingly physical and not digitalized. MoXey is committed to streamline the finance operations of Land Freight industry, by automating invoicing, collections, and payments.",
    date: "November, 07 2022",
    read: "4 min read",
  },
  {
    title: "Digital payments are innovating the trucking industry",

    thumbnail: blogThumbnail3,
    short_desc:
      "MoXey is enabling truckers and drivers to get paid instantly eliminating paperwork like invoices or bills and making your freight completely cash-less",
    date: "May, 05 2023",
    read: "5 min read",
  },
  {
    title:
      "Attention, Transporters! Your Payment-Related Troubles Are About to VanishTrucking Industry",
    thumbnail: blogThumbnail4,
    short_desc:
      "Paying your drivers online is now easy. With many Freight-tech changing how payments are made in the industry, MoXey is simplifying payments, fast-forwarding your freight.",
    date: "November, 14 2022",
    read: "4 min read",
  },
  {
    title: "Ultimate guide to Expense Management-Freight fast and save more",
    thumbnail: blogThumbnail5,
    short_desc: `Wondering how to minimize your expenses in your freight business?
    Running any kind of business is expensive but running a trucking business is really expensive.
    In freight industry one of the major challenges is to monitor all the expenses.`,
    date: "November, 17 2022",
    read: "6 min read",
  },
  {
    title: "What's the best payment gateway for your Freight business?",
    thumbnail: blogThumbnail6,
    short_desc: `Payment gateways are essential for any freight business, allowing transporters to make payments quickly and securely. In this article, we will discuss the benefits of payment gateways for freight businesses, how to choose the right payment gateway, security considerations, popular payment gateways, and tips for choosing the right payment gateway.`,
    date: "November, 20 2022",
    read: "5 min read",
  },
  {
    title: "Benefits of Digital Payments for Fleet Management",
    thumbnail: blogThumbnail1,
    short_desc: `The Digital payments for fleet management are
    enormous, and they are becoming a more and more popular way of
    payment in today's world`,
    date: "March, 1 2023",
    read: "3 min read",
  },
  {
    title: "Implementing a Fleet Digital Payment System: Best Practices",
    thumbnail: blogThumbnail2,
    short_desc: `Fleet payments are one area where digitization can make a significant difference. Traditional payment methods, such as cash and checks, can be time-consuming, inconvenient, and error-prone`,
    date: "March, 5 2023",
    read: "5 min read",
  },
  {
    title: "The Future of Fleet Payments: Emerging Trends and Innovations",
    thumbnail: blogThumbnail2,
    short_desc: `Fleet payments are one area where digitization can make a significant difference. Traditional payment methods, such as cash and checks, can be time-consuming, inconvenient, and error-prone`,
    date: "March, 9 2023",
    read: "4 min read",
  },
  {
    title: "Top 10 Benefits of Expense Management",
    thumbnail: blogThumbnail10,
    short_desc: `Effective spending management goes beyond simply keeping track of financial records in the dynamic business environment of today.`,
    date: "August, 30 2023",
    read: "5 min read",
  },
  {
    title: "From Basics to Solutions: Why your business needs Payroll Manangement?",
    thumbnail: blogThumbnail11,
    short_desc: `Payroll management encompasses the systematic administration of employee compensation, benefits, deductions, and tax withholdings.`,
    date: "August, 30 2023",
    read: "4 min read",
  },
  {
    title: "Fintech Payroll vs Traditional Payroll: A Comparative Analysis",
    thumbnail: blogThumbnail12,
    short_desc: `Explore the differences between Fintech Payroll and Traditional Payroll systems in our detailed comparative analysis. Make informed decisions for your business payroll processes. Discover the future of payroll management`,
    date: "October, 04 2023",
    read: "3 min read",
  },
];

function blogsComponent() {
  return (
    <div className="moxey-blogs">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="moxey-blogs-line-1" data-aos="fade-down">
              Understanding Fintech
            </h1>
            <p className="me-5 text-light">
              To understand more about the digital financial ecosystem provided
              by MoXey and the contribution of fintech for land freight
              industry, read all publications and documents here.
            </p>
            <Link to="/Blogs" className="text-decoration-none mt-5">
              <PrimaryActionButton
                data-aos="fade-in"
                name="all publications"
                variant="dark"
                className="mt-5 mb-3 d-none d-md-block d-lg-block btn-blog"
              />
            </Link>
          </div>
          <div className="col-lg-6">
            <div className="moxey-blogs-cards-wrapper">
              {blogs.map((blog, index) => (
                <BlogCard item={blog} index={index} key={index} animation />
              ))}
            </div>
            <Link to="/Blogs" className="text-decoration-none">
              <PrimaryActionButton
                data-aos="fade-in"
                name="all publications"
                variant="dark"
                className="mt-5 mb-3 d-block d-md-none d-lg-none text-decoration-none"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export const BlogCard = ({ item, index, animation }) => {
  if (index >= 3) return;
  return (
    <div
      className="card p-3 rounded-4 blog-card pb-0"
      data-aos={animation ? "fade-left" : null}
      data-aos-delay={100 * index}
    >
      <Link to={`/blog/${generateBlogRoute(index + 1)}`}>
        <img src={item.thumbnail} className="card-img-top rounded-3" />
      </Link>
      <div className="card-body">
        <h4 className="card-title fw-bold title">{item.title}</h4>
        <p className="card-text short-desc">{item.short_desc}</p>
        <div className="d-flex justify-content-between mt-4">
          <p className="card-date">
            {item.date} - {item.read}
          </p>
          <div className="d-flex">
            <img src={shareIcon} className="me-3 blog-icons" />
            <img src={bookmarkIcon} className=" blog-icons" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default blogsComponent;
