import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./contact.style.scss";
import { contactURL, baseURL } from "../../constants/env";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "./contact_bg.png";

const metaData = {
  title: "Contact Us - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

const defaultFormFields = {
  name: "",
  email: "",
  contact: "",
  query: "",
};

function ContacPage() {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [success, setSuccess] = useState(false);
  const link = useRef();
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const submitQuery = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${baseURL}${contactURL}`, formFields);
      if (res.data.status === "success") setSuccess(true);
    } catch (error) {
      console.error(error);
      alert("Something Went Wrong! Please Try Again..")
      navigate("/");
    }
  };

  return (
    <div className="moxey-contact">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/contact`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/contact`} />
      </Helmet>
      <div>
        <div className="moxey-contact-header">
          {success ? "" : <h1>Contact</h1>}
        </div>
        {success ? (
          <div className="row w-100 pb-5">
            <div className="col-lg-6 col-sm-12 offset-lg-3 p-0">
              <div className=" card p-5 moxey-contact-body text-center">
                <h4 className="text-secondary">
                  Thank you for contacting MoXey!
                </h4>
                <p className="text-muted my-4">
                  We look forward to serving you and will be in touch with you
                  shortly.
                </p>

                <div className="d-grid">
                  <div className="col-lg-4 offset-lg-4">
                    <button
                      onClick={() => goHome()}
                      className="btn btn-success btn-submit px-4"
                    >
                      Explore MoXey
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="moxey-contact-body">
            <form
              className="container"
              onSubmit={(event) => submitQuery(event)}
            >
              <div className="row w-100 m-0 p-0">
                <div className="col-lg-6 col-sm-12 offset-lg-3 p-0">
                  <div className="moxey-contact-form">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Full Name
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        onChange={handleChange}
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contact" className="form-label">
                        Phone Number
                      </label>
                      <input
                        onChange={handleChange}
                        type="tel"
                        className="form-control"
                        id="contact"
                        name="contact"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="query" className="form-label">
                        What can we help you with ?
                      </label>
                      <textarea
                        onChange={handleChange}
                        className="form-control"
                        id="query"
                        rows="3"
                        name="query"
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-success btn-submit"
                        data-bs-toggle="modal"
                        data-bs-target="#successForm"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

function Mailto({ formFields, submit, ...props }) {
  const moxey_email = "contact@moxey.ai";
  return (
    <a
      ref={props.link}
      href={`mailto:${moxey_email}?subject= Query From | ${
        formFields.name
      } &body=${EmailBodyTemplate(formFields) || ""}`}
      className="btn btn-success btn-submit"
    >
      {props.children}
    </a>
  );
}

const EmailBodyTemplate = (formFields) =>
  `${formFields.name} \r ${formFields.contact} \r ${formFields.email} \r uery :  ${formFields.query}`;

export default ContacPage;
