export const META_BLOGS = {
  1: {
    meta_title:
      "Save Big with Fleet Cards - Maximize Savings on Fuel and Maintenance",
    meta_desc:
      "Discover the power of fleet cards to save money on fuel and maintenance costs. Unlock exclusive discounts and streamline expense management",
    meta_keywords:
      "fleet cards, fuel savings, maintenance savings, expense management, exclusive discounts, cost optimization",
  },
  2: {
    meta_title:
      "	Revolutionary FreightTech Solution: Streamline Shipping and Logistics",
    meta_desc:
      "Discover a game-changing FreightTech platform that revolutionizes shipping and logistics, optimizing efficiency and cost-effectiveness.",
    meta_keywords:
      "Fintech, FreightTech, shipping, logistics, technology, supply chain",
  },
  3: {
    meta_title: "Digital Payments Revolutionizing Trucking Industry",
    meta_desc:
      "Discover how digital payments are transforming the trucking industry, simplifying transactions and improving efficiency. Stay updated with the latest innovations",
    meta_keywords:
      "UAE trucking industry, Digital payments, Payment solutions for trucks, Electronic payment systems, Digital transformation in trucking, Mobile payment solutions for logistics, Cashless transactions in transportation, E-commerce and digital payments in UAE trucking, Innovations in trucking payments, Fleet management solutions with digital payments.",
  },
  4: {
    meta_title:
      "Payment Solutions for Transporters: Say Goodbye to Payment-Related Issues",
    meta_desc:
      "Discover reliable payment solutions designed specifically for transporters. Streamline your transactions and eliminate payment troubles effortlessly.",
    meta_keywords:
      "payment solutions, transporters, payment-related issues, streamline transactions, reliable payments",
  },
  5: {
    meta_title: "Fleet Digital Payment System: Best Practices Guide",
    meta_desc:
      "	Discover the best practices for implementing a fleet digital payment system. Learn how to optimize your fleet management with efficient and secure payment solutions.",
    meta_keywords:
      "fleet digital payment system, digital payment solutions, ACH payments, credit card payments, real-time reporting, accounting software integration, vendor communication, training and support, UAE.",
  },
  6: {
    meta_title:
      "Discover the ideal payment gateway for your freight business. Ensure reliable and secure payment processing for seamless transactions",
    meta_desc:
      "payment gateway, freight business, reliable, secure, payment processing, seamless transactions.",
    meta_keywords: "Best Payment Gateway for Freight Business",
  },
  7: {
    meta_title:
      "The Advantages of Digital Payments for Efficient Fleet Management",
    meta_desc:
      "Discover the advantages of using digital payments in fleet management. Streamline transactions, boost efficiency, and enhance financial control with secure and convenient digital payment solutions for your fleet operations",
    meta_keywords:
      "Digital payments, Fleet management, Cost savings, Time efficiency, Payment tracking, Increased transparency",
  },
  8: {
    meta_title: "Fleet Digital Payment System: Best Practices Guide",
    meta_desc:
      "Discover the best practices for implementing a fleet digital payment system. Learn how to optimize your fleet management with efficient and secure payment solutions.",
    meta_keywords:
      "fleet digital payment system, digital payment solutions, ACH payments, credit card payments, real-time reporting, accounting software integration, vendor communication, training and support, UAE.",
  },
  9: {
    meta_title: "Future of Fleet Payments: Trends and Innovations Explained",
    meta_desc:
      "Discover the latest trends and innovations shaping the future of fleet payments. Stay ahead of the game with insights on emerging technologies and convenient payment solutions for managing your fleet efficiently",
    meta_keywords:
      "fleet payments, digital payments, mobile payments, digital wallets, data analytics, blockchain-based payment systems, biometric authentication, fleet management, fuel consumption, maintenance schedules, vehicle usage, UAE region.",
  },
  9: {
    meta_title: "Streamline Fleet Operations with Automated Digital Payments",
    meta_desc:
      "Optimize fleet operations with automated digital payments. Streamline transactions, improve efficiency, and enhance cash flow for seamless fleet management.",
    meta_keywords:
      "UAE fleet operations, digital payments UAE, fleet management UAE, cash flow management UAE, automated payments UAE, financial management UAE, Moxey UAE, transportation industry UAE.",
  },
  10: {
    meta_title: "Expense Management Made Easy: Best App for Tracking and Budgeting in UAE",
    meta_desc:
      "Discover the Power of Expense Management with the Best Money Manager App in UAE! Effectively track, manage, and optimize your expenses with our cutting-edge expense solution. Enhance financial control, streamline budgeting, and experience seamless expense tracking.",
    meta_keywords:
      "money manager app, expense management, Expense solution, expense tracker app, best expense tracker app, expenses tracker, app budgeting",
  },
  11: {
    meta_title: "Exploring Payroll Management: From Definition to Cloud-Based Solutions",
    meta_desc:
      "Discover the essence of payroll: its definition, benefits, and undeniable importance. Learn why your business needs efficient payroll management and the role of advanced payroll software solutions.",
    meta_keywords:
      "payroll software, payroll management, payroll solutions,   cloud payroll",
  },
  12: {
    meta_title: "Fintech vs Traditional Payroll: Exploring the Differences and Advantages",
    meta_desc:
      "Explore the differences between Fintech Payroll and Traditional Payroll systems in our detailed comparative analysis. Make informed decisions for your business payroll processes. Discover the future of payroll management",
    meta_keywords:
      "payroll management system, manage payroll, payroll management services, hrms payroll, employee payroll management system, payroll processing system",
  },
};
