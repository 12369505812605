// importing components
import HeroSection from "../../components/Home/hero.component";
import HeroSubSection from "../../components/Home/hero-sub-section.component";
import AboutSection from "../../components/Home/about.component";
import ProductsSection from "../../components/Home/products.component";
import BlogSection from "../../components/Home/blogs.component";
import SupportSection from "../../components/Home/support.component";
import PaymentSection from "../../components/Home/payments.component";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "MoXey | Digital payment solution for mobility & logistic sectors",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

function Home() {
  return (
    <div>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content="Moxey is a Data-driven digital payments and lending solutions for fleet owners and operators." />
        <meta property="og:url" content={metaData.base} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content="Moxey is a Data-driven digital payments and lending solutions for fleet owners and operators." />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={metaData.base} />
      </Helmet>
      <HeroSection />
      <HeroSubSection />
      <AboutSection />
      <ProductsSection />
      <SupportSection />
      <PaymentSection />
      <BlogSection />
    </div>
  );
}

export default Home;
