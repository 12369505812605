import { useEffect, useState } from "react";

import PrimaryActionBtn from "../ui/Buttons/primary-button.component";
import { OnlyInMobile } from "../Responsive/responsive.component";

import "./payments.style.scss";
import featureFleetImg from "./imgs/features/feature_fleet_partner.png";
import featureMerchants from "./imgs/features/feature_merchants.png";
import featureTransporter from "./imgs/features/feature_transporter.png";

const featuresList = [
  {
    id: "fleet-partners",
    title: "Enjoy Real-Time and Convenient Payments",
    type: "Fleet Partners",
    list: [
      "No more waiting for collecting cash",
      "Earn Rewards, cashbacks and discounts",
      "24X7 transfers available real-time",
      "Request payment on document approval",
      "Convenience of a wallet, security of a bank account ",
      "As safe as a bank account",
    ],
    img: featureFleetImg,
    button: "get moxey",
    url: "https://play.google.com/store/apps/details?id=com.moxeywallet&hl=en&gl=US",
  },
  {
    id: "transporters",
    title: "Experience No More Cash Leakage",
    type: "Transporters",
    list: [
      "Reduced operational cost",
      "Track and monitor payment history",
      "Dashboards for real time insights",
      "Digitized transaction records",
      "Streamlined business processes",
      "Expense management",
    ],
    img: featureTransporter,
    button: "request demo",
    url: "/contact",
  },
  {
    id: "merchants",
    title: "We take your business personally",
    type: "Merchants",
    list: [
      "Automated transaction reporting",
      "Boosted sales and increased customer base",
      "Build curated loyalty programs",
      "Manage inventory data for multiple purchases",
      "Increase customer loyalty and repeat purchases",
      "Easy settlement in bank accounts",
    ],
    img: featureMerchants,
    button: "request demo",
    url: "/contact",
  },
];

const breakpoints = [7200, 7900, 8400];

function PaymentsSection() {
  const [offset, setOffset] = useState(0);

  const onScroll = () => setOffset(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="moxey-payments w-100" id="moxey-payments">
      <div className="container">
        <h1
          className="moxey-payments-headline text-gradient animated"
          data-aos="fade-in"
        >
          We are committed to serving all our stakeholders
        </h1>
        <div className="row">
          <div className="col-sm-12 col-lg-5">
            <div className="moxey-payments-img sticky-top pt-5">
              {offset > breakpoints[0] && offset < breakpoints[1] ? (
                <MoxeyFeatureImage src={featureFleetImg} />
              ) : null}
              {offset > breakpoints[1] && offset < breakpoints[2] ? (
                <MoxeyFeatureImage src={featureTransporter} />
              ) : null}
              {offset > breakpoints[2] ? (
                <MoxeyFeatureImage src={featureMerchants} />
              ) : null}
            </div>
          </div>
          <div className="col-sm-12 col-lg-7">
            {featuresList.map((feature, index) => (
              <Feature feature={feature} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// feature component
const Feature = ({ feature }) => {
  return (
    <div
      className="moxey-payments-feature-wrapper"
      data-aos="fade-up"
      data-aos-delay="200"
      id={feature.id}
    >
      <h1 className="moxey-payments-line-1 fw-bold" data-aos="fade-up">
        {feature.title}
      </h1>
      <h3 className="moxey-payments-line-2" data-aos="fade-up">
        {feature.type}
      </h3>

      <div className="text-center">
        <OnlyInMobile>
          <img
            className="moxey-payments-feature-img img-fluid"
            src={feature.img}
            data-aos="fade-in"
          />
        </OnlyInMobile>
      </div>

      <ul className="moxey-payments-features-list" data-aos="fade-in">
        {feature.list.map((item, index) => (
          <li className="moxey-payments-feature" key={index}>
            {item}
          </li>
        ))}
      </ul>

      <div className="action">
        <PrimaryActionBtn name={feature.button} url={feature.url} />
      </div>
    </div>
  );
};

function MoxeyFeatureImage({ src }) {
  return (
    <img
      className="img-fluid  d-none d-md-block d-lg-block"
      src={src}
      data-aos="fade-in"
    />
  );
}

export default PaymentsSection;
