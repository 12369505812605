import { useState } from "react";
import "./hero.style.scss";

import PlayIcon from "./imgs/play_btn_icon.png";
import DownloadIcon from "./imgs/download_icon_btn.png";
import HeroBannerImg from "./imgs/hero_banner_img.png";
import HeroBannerImgSmall from "./imgs/hero_banner_img_sm.png";

import PrimaryActionButton from "../ui/Buttons/primary-button.component";
import PlayButton from "../ui/Buttons/secondary-button.component";

const HeroComponent = () => {
  const URL = "https://www.youtube.com/embed/6gaFVaPNfmg";
  const [watchVideoUrl, setWatchVideoUrl] = useState(URL);
  const playVideo = () => setWatchVideoUrl(URL);
  const pauseVideo = () => setWatchVideoUrl("");
  return (
    <>
      <div id="moxey-hero" className="moxey-hero">
        <div className="container ">
          <div className="moxey-hero-content">
            <div className="row w-100">
              <div className="col-sm-12 col-md-6 col-lg-6 m-0">
                <div className="hero-line">
                  <h5 className="hero-line-1" data-aos="fade-down">
                    Enter the new age of
                  </h5>
                  <h1
                    className="hero-line-2"
                    data-aos="fade-right"
                    data-aos-delay="500"
                  >
                    Digital Financial Ecosystem
                  </h1>
                  <h5
                    className="hero-line-3"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    for Mobility
                  </h5>
                  <p
                    className="text-light mt-5 pe-5"
                    data-aos="fade-in"
                    data-aos-delay="900"
                  >
                    Data-driven digital payments and lending solutions for fleet
                    owners and operators
                  </p>
                </div>
                {/* Action buttons */}
                <div className="hero-cta mt-5" data-aos="fade-up">
                  <PrimaryActionButton
                    name="download MoXey"
                    className={"me-4"}
                    icon={DownloadIcon}
                    url={
                      "https://play.google.com/store/apps/details?id=com.moxeypay"
                    }
                  />
                  <PlayButton
                    name="watch the video"
                    className={""}
                    onClick={playVideo}
                    icon={PlayIcon}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 p-0">
                <div className="moxey-hero-banner-wrapper">
                  <picture>
                    <source
                      media="(max-width: 576px)"
                      srcSet={HeroBannerImgSmall}
                    />
                    <img
                      className="banner-img"
                      src={HeroBannerImg}
                      data-aos="fade-left"
                      data-aos-delay="1000"
                      alt="Moxey - Beyond Payments"
                    />
                  </picture>
                </div>
              </div>
            </div>

            {/* Action buttons on sall screen*/}
            <div className="d-md-none d-lg-none d-flex flex-column w-100 mx-auto mt-5" data-aos="fade-up">
                  <PrimaryActionButton
                    name="download MoXey"
                    className={"mx-auto"}
                    icon={DownloadIcon}
                    url={
                      "https://play.google.com/store/apps/details?id=com.moxeypay"
                    }
                  />
                  <PlayButton
                    name="watch the video"
                    className={"mx-auto mt-3"}
                    onClick={playVideo}
                    icon={PlayIcon}
                  />
                </div>
          </div>
        </div>
      </div>
      {/* watch video modal  */}
      <WatchVideoModal url={watchVideoUrl} onPause={pauseVideo} />
    </>
  );
};

function WatchVideoModal({ url, onPause }) {
  return (
    <div
      className="modal fade moxey-watch"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" data-bs-dismiss="modal">
        <div className="modal-content bg-transparent">
          <div
            className="modal-body moxey-watch-body text-white p-0"
            onClick={onPause}
          >
            <button
              className="btn text-white"
              data-bs-dismiss="modal"
              onClick={onPause}
            >
              Close
            </button>
            <iframe
              className="shadow watch-video"
              src={url}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroComponent;
