// adding bootstrap to react app
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

// initialzing the react app
import React from "react";
import ReactDOM from "react-dom/client";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./modules/scrollToTop";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const APP = (
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  root.render(APP);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
