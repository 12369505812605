import "./agreements.style.scss";

import { Helmet } from "react-helmet-async";
import MetaThumbnail from "../Blog/imgs/Blogs_01.png";

const metaData = {
  title: "User Data Policy - Moxey.ai",
  thumbnail: MetaThumbnail,
  base: "https://moxey.ai",
  keywords: "fuel payment in uae, fleet payment in uae, digital payment for mobility, logistics payment, mobility payment, transporter payment, best digital payment for transporter, expense tracker for transporter, fuel card in UAE, fleet card, prepaid card, mobility & logistic payment, Cashless Payments",
  description: "Moxey provides mobility & logistic sectors with the tech platform needed for the development of a digitally payment system in MENA.",
};

function PrivacyComponent() {
  return (
    <div className="moxey-agreements">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={`${metaData.base}/data-policy`} />
        <meta property="og:image" content={`${metaData.base}${metaData.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image:src" content={`${metaData.base}${metaData.thumbnail}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`${metaData.base}/data-policy`} />
      </Helmet>
      <h1 className="text-center">User Data Policy</h1>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="content bg-white rounded-3">
              <div className="data-policy-content">
                <p><strong>Learn how to delete your MoXey App Account and User Details</strong></p>
                <p>In order to delete your data from MoXey Pay or MoXey Merchant App, simply follow these steps:</p>
                <ol>
                  <li>Contact MoXey Support Team at <a href="mailto:support@moxey.ai">support@moxey.ai </a> or <a href="tel:+97160053355">+97160053355</a>.</li>
                  <li>Provide your basic information, business details(if you are a Merchant) and mention your reason for data deletion from MoXey App.</li>
                  <li>On successful validation, Support Team will confirm the successful deactivation of your account.</li>
                </ol>
                <p><strong>What happens if I permanently delete my MoXey App User Account?</strong></p>
                <ul>
                  <li>On deactivation, your login credentials will stand invalid and you will not be able to login on the platform.</li>
                </ul>
                <p>In case you are a Merchant, please be advised that the following conditions are also applicable.</p>
                <ul>
                  <li>Your products will be delisted from the MoXey Platform.</li>
                  <li>However, your sales report will stay in the MoXey system for the next 1 year.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyComponent;
