import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_04.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title:
      "Attention, Transporters! Your Payment-Related Troubles Are About to Vanish",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[4].meta_keywords} />
        <meta name="description" content={META_BLOGS[4].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[4].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("4")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[4].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("4")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-4 text-center"
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          Attention, Transporters! Your Payment-Related Troubles Are About to
          Vanish
        </h1>
        <h5 className="fw-light">A Journey Towards Paperless Freight</h5>
        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>November, 14 2022 - 4 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            Transporters play a pivotal role in the logistics industry, ensuring
            goods reach their destinations efficiently and on time. However, the
            life of a transporter is fraught with challenges, from driver
            payouts to in-trip expenses. These tasks are often complicated
            further by traditional methods of operation. In this blog, we will
            delve into the pain points experienced by transporters and how
            MoXey, a financial technology solution, can help alleviate these
            challenges.
          </p>

          <p>Every Transporter's Pain Points:</p>

          <p>
            Shortage of Truck Drivers: Truck drivers face a multitude of issues,
            including substandard vehicles, challenging interactions with
            authorities at state borders, and a lack of proper sanitation
            facilities. However, the most significant issue that drives truck
            drivers away from the profession is the delay in receiving their
            income. Despite earning an average monthly wage of Rs. 15,000 to
            20,000, many drivers do not get paid on time.
          </p>

          <p>
            Evolving Client Demands: Modern consumers expect goods to be
            delivered faster than ever before. Transporters, especially small
            and medium-scale ones, must strike a delicate balance between
            adopting technology and providing a personalized service to meet
            these evolving demands while staying competitive.
          </p>

          <p>
            Maintaining Documents: Timely access to relevant documents is
            crucial to ensure that transporters receive payments on time. In
            many cases, drivers are responsible for procuring and maintaining
            these documents as they interact directly with clients. Given the
            long distances they travel before reuniting with transporters, the
            physical documents are at risk of being lost or misplaced.
          </p>
          <p>How Can MoXey Help?</p>

          <p>
            Instant Payments: MoXey addresses the most pressing concern for
            drivers - receiving payments on time. The MoXey portal streamlines
            the process of transferring salaries, wages, and advance payments,
            ensuring drivers get their earnings promptly. Additionally, the
            portal provides access to the entire driver database, enhancing
            decision-making efficiency.
          </p>

          <p>
            Real-time Insights: Leveraging day-to-day data, the MoXey portal
            generates insights and presents them on a digital dashboard. This
            dashboard offers end-to-end visibility of your data, expediting the
            decision-making process.
          </p>

          <p>
            Document Management: MoXey's digital platform eliminates the risks
            associated with physical document management. It provides a secure
            and organized way to store and access essential documents, reducing
            the chances of loss or misplacement.
          </p>

          <p>MoXey: A Transporter's Best Friend</p>
          <p>
            MoXey offers innovative payment solutions designed to address the
            unique challenges faced by transporters. With instant payments,
            real-time insights, and efficient document management, MoXey
            empowers transporters to enhance their operations and ensure drivers
            are paid promptly. If you're a transporter looking to streamline
            your financial processes and improve efficiency, MoXey is your
            trusted partner.
          </p>

          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
