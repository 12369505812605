import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";
import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_02.png";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title: "Fintech Which Is Transforming Freight Like Never Before",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[2].meta_keywords} />
        <meta name="description" content={META_BLOGS[2].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[2].meta_desc} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("2")}`}
        />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[2].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("2")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-2 text-center"
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          Fintech Which Is Transforming Freight Like Never Before
        </h1>
        <h5 className="fw-light">A Journey Towards Paperless Freight</h5>
        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>November, 07 2022 - 4 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <p>
            The freight industry, often referred to as the backbone of the
            global economy, is a complex and vital sector that keeps goods
            moving across the world. However, despite its critical role, the
            freight industry has been slow to embrace technological innovations,
            resulting in outdated, manual, and inefficient processes. This is
            where fintech is stepping in to revolutionize the way the freight
            industry operates.
          </p>
          <p>
            In this blog, we will explore how fintech, with a special focus on
            MoXey, is transforming the freight industry like never before by
            streamlining financial operations, enabling data-driven payments,
            and enhancing supply chain resilience.
          </p>
          <p>The Challenges of the Conventional Freight Industry</p>
          <p>
            Traditionally, the freight industry has been characterized by
            physical and non-digital processes. The industry is highly
            fragmented, with numerous stakeholders involved in shipping and
            transportation, relying on manual procedures for tasks such as
            invoicing, collections, and payments. These outdated methods have
            led to inefficiencies, lack of transparency, and financial stress
            for many participants.
          </p>
          <p>The Need for a Digital Revolution</p>

          <p>
            Enter MoXey, a fintech solution designed to address the shortcomings
            of the conventional freight industry. MoXey is on a mission to
            transform freight into a more streamlined, efficient, and
            digitalized sector. Let's delve into how MoXey is achieving this
            transformation:
          </p>

          <p>
            1. Automating Financial Operations: One of the core objectives of
            MoXey is to automate financial operations within the land freight
            industry. This includes tasks like invoicing, collections, and
            payments. By digitizing these processes, MoXey eliminates the need
            for labor-intensive manual work, saving time, money, and effort.
          </p>

          <p>
            2. Data-Driven Payments: In an era where data is king, MoXey
            recognizes the importance of data-driven payments. The freight
            industry is increasingly focused on quick and hassle-free
            transactions, and MoXey delivers precisely that. Through app and
            card-based solutions, MoXey offers instant settlement, replacing the
            time-consuming settlement processes that used to be contingent on
            factors like invoice dates and proof of delivery.
          </p>

          <p>
            3. Empowering Small and Medium-Sized Players: The freight industry
            comprises a significant number of small and medium-sized businesses
            that often lack access to credit. MoXey steps in to enhance supply
            chain resilience by facilitating working capital, expediting freight
            movement, and empowering these businesses. With MoXey, even smaller
            players can compete on a level playing field.
          </p>
          <p>
            4. Eliminating Inconsistent Lumper Payments: In the logistics
            industry, inconsistent lumper payments have been a significant
            source of stress. These payments are typically made to third-party
            workers who assist with loading and unloading trailer contents.
            MoXey's prepaid expense cards, equipped with predefined limits,
            ensure a more transparent process and provide freight players with
            greater control over their operations.
          </p>
          <p>
            5. Leveraging Data Insights: MoXey recognizes the untapped potential
            of data within the freight industry. As supply chains become more
            complex, the demand for data-driven insights increases. MoXey is
            well-positioned to extract and deliver these insights, helping
            companies navigate the intricacies of the industry more effectively.
          </p>

          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
