import "./button.style.scss";

const ButtonComponent = ({ name, className, variant, url, icon }) => {
  return (
    <a
      className={`btn btn-lg btn-outline-light primary-action-btn text-uppercase ${variant} ${className}`}
      href={url}
    >
      {icon && <img className="me-2" src={icon} />}
      <span className="btn-text">{name}</span>
    </a>
  );
};

export default ButtonComponent;
