import { Link } from "react-router-dom";
import { HashLink as HLink } from "react-router-hash-link";

import "./footer.style.scss";
import Logo from "./moxey-logo.svg";
import facebookIcon from "./icons/facebook-icon.svg";
import linkedinIcon from "./icons/linkedin-icon.svg";
import twitterIcon from "./icons/twitter-icon.svg";
import youtubeIcon from "./icons/youtube-icon.svg";
import InstaIcon from "./icons/instagram.png";
import TikIcon from "./icons/tik-tok.png";
import locationIcon from "./icons/location-icon.svg";
import phoneIcon from "./icons/phone-icon.svg";

import appStore from "./icons/download_store_app.png";
import playstore from "./icons/download_store_play.png";
import pciCertifiedLogo from "./icons/pci-certified.png";

const downloadLinks = {
  play_Store: "https://play.google.com/store/apps/details?id=com.moxeypay",
  apple_store: "https://apps.apple.com/in/app/moxey-pay/id6443848727",
};

const SOCIAL_LINKS = [
  {
    name: "linkedin",
    icon: linkedinIcon,
    url: "https://www.linkedin.com/company/moxeyai/",
  },
  {
    name: "facebook",
    icon: facebookIcon,
    url: "https://www.facebook.com/moxeyai",
  },
  {
    name: "instagram",
    icon: InstaIcon,
    url: "https://www.instagram.com/moxey.ai/",
  },
  {
    name: "twitter",
    icon: twitterIcon,
    url: "https://twitter.com/moxeyai",
  },
  {
    name: "youtube",
    icon: youtubeIcon,
    url: "https://www.youtube.com/channel/UCJ87myU200MAn28Vyh9JsCg",
  },
  {
    name: "tiktok",
    icon: TikIcon,
    url: "https://www.tiktok.com/@moxeyai",
  },
];

function FooterComponent() {
  return (
    <div className="moxey-footer">
      <div className="container py-0 my-0 mb-5">
        <img className="moxey-footer-logo" src={Logo} alt="moxey footer logo" />
        <div className="row w-100 m-0 pt-4 gx-5">
          {/* footer info content */}
          <div className="col-sm-12 col-md-6 col-lg-6 ps-0">
            <p className="moxey-footer-line-1 m-0 pe-sm-4">
              MOXEY is democratizing access to digital payment channels for
              businesses, empowering them with modern financial tools that
              streamline daily operations and encourage growth while boosting
              financial inclusion for the underserved and unbanked.
            </p>
            <h1 className="moxey-footer-line-2 my-5">Beyond Payments</h1>
            <img
              className="pci-logo"
              src={pciCertifiedLogo}
              alt="download from apple store"
            />
          </div>
          {/* footer links */}
          <div className="col-sm-12 col-lg-6 p-0">
            <div className="row w-100">
              <div className="col-sm-12 col-lg-8">
                <div className="row w-100">
                  <div className="col-6 ">
                    {/* rendering footer links */}
                    <ul className="moxey-footer-menu">
                      <li className="moxey-footer-menu-item">
                        <HLink
                          to="/#moxey-about"
                          className="text-decoration-none text-capitalize"
                        >
                          about us
                        </HLink>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <HLink
                          to="/#moxey-products"
                          className="text-decoration-none text-capitalize"
                        >
                          products
                        </HLink>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <HLink
                          to="/#moxey-payments"
                          className="text-decoration-none text-capitalize"
                        >
                          features
                        </HLink>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <Link
                          to="/Blogs"
                          className="text-decoration-none text-capitalize"
                        >
                          publications
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="moxey-footer-menu">
                      <li className="moxey-footer-menu-item">
                        <Link
                          to="/faqs"
                          className="text-decoration-none text-capitalize"
                        >
                          help
                        </Link>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <Link
                          to="/contact"
                          className="text-decoration-none text-capitalize"
                        >
                          contact us
                        </Link>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <Link
                          to={"/terms"}
                          className="text-decoration-none text-capitalize"
                        >
                          terms of use
                        </Link>
                      </li>
                      <li className="moxey-footer-menu-item">
                        <Link
                          to={"/privacy"}
                          className="text-decoration-none text-capitalize"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="moxey-footer-menu-item d-none">
                        <Link
                          to={"/data-policy"}
                          className="text-decoration-none text-capitalize"
                        >
                          Data Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="moxey-footer-social-links d-flex mt-4">
                  {SOCIAL_LINKS.map((item, index) => (
                    <a href={item.url} key={index}>
                      <img src={item.icon} alt={item.name} />
                    </a>
                  ))}
                </div>
              </div>
              {/* Get App Footer Section */}
              <div className="col-sm-12 col-md-6 col-lg-4 pe-0  ">
                <div className="get-app">
                  <h6 className="get-app-text text-capitalize mb-3 ms-3">
                    get it on
                  </h6>
                  <div className="get-app-buttons">
                    <a href={downloadLinks.play_Store} target="_blank">
                      <img
                        className="get-app-icon"
                        src={playstore}
                        alt="download from apple store"
                      />
                    </a>
                    <a href={downloadLinks.apple_store} target="_blank">
                      <img
                        className="get-app-icon"
                        src={appStore}
                        alt="download from apple store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="moxey-footer-end">
        <div className="container d-flex justify-content-between">
          <div className="d-flex">
            <img className="icon" src={locationIcon} />
            <div> Al Sila Tower, Al Maryah Island, Abu Dhabi, UAE</div>
          </div>
          <div className="d-flex">
            <img className="icon" src={phoneIcon} />
            <div>+971 600524642</div>
          </div>
        </div>
        <div className="container">
          <p className="my-5">
            Copyright © 2022.
            <br />
            The MoXey Tech Limited card is issued by Arab Bank, pursuant to
            license by Visa. Visa is a registered trademark of Visa Inc
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
