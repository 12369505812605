import { Routes, Route } from "react-router-dom";

import { routes } from "./routes";

// importing pages
import AppLayout from "./components/Layout/app.layout";
import Home from "./routes/Home/home.component";
import Blogs from "./routes/Blogs/blogs.component";
import Blog1 from "./routes/Blog/Blog1.component";
import Blog2 from "./routes/Blog/Blog2.component";
import Blog3 from "./routes/Blog/Blog3.component";
import Blog4 from "./routes/Blog/Blog4.component";
import Blog5 from "./routes/Blog/Blog5.component";
import Blog6 from "./routes/Blog/Blog6.component";
import Blog7 from "./routes/Blog/Blog7.component";
import Blog8 from "./routes/Blog/Blog8.component";
import Blog9 from "./routes/Blog/Blog9.component";
import Blog10 from "./routes/Blog/Blog10.component";
import Blog11 from "./routes/Blog/Blog11.component";
import Blog12 from "./routes/Blog/Blog12.component";
import Faqs from "./routes/Faqs/faqs.component";
import Contact from "./routes/Contact/contact.component";
import Policy from "./routes/Agreements/privacy.component";
import Terms from "./routes/Agreements/terms.component";
import DataPolicy from "./routes/Agreements/data-policy.component";
import NotFound from "./routes/NotFound/404";

const App = () => {
  return (
    <Routes>
      <Route exact path={routes.index} element={<AppLayout />}>
        <Route exact index element={<Home />} />
        <Route exact path={routes.blogs} element={<Blogs />} />
        <Route exact path={routes.blog.blog_1} element={<Blog1 />} />
        <Route exact path={routes.blog.blog_2} element={<Blog2 />} />
        <Route exact path={routes.blog.blog_3} element={<Blog3 />} />
        <Route exact path={routes.blog.blog_4} element={<Blog4 />} />
        <Route exact path={routes.blog.blog_5} element={<Blog5 />} />
        <Route exact path={routes.blog.blog_6} element={<Blog6 />} />
        <Route exact path={routes.blog.blog_7} element={<Blog7 />} />
        <Route exact path={routes.blog.blog_8} element={<Blog8 />} />
        <Route exact path={routes.blog.blog_9} element={<Blog9 />} />
        <Route exact path={routes.blog.blog_10} element={<Blog10 />} />
        <Route exact path={routes.blog.blog_11} element={<Blog11 />} />
        <Route exact path={routes.blog.blog_12} element={<Blog12 />} />
        <Route exact path={routes.faqs} element={<Faqs />} />
        <Route exact path={routes.contact} element={<Contact />} />
        <Route exact path={routes.privacy} element={<Policy />} />
        <Route exact path={routes.terms} element={<Terms />} />
        <Route exact path={routes.data_policy} element={<DataPolicy />} />
        <Route exact path={routes.NOT_FOUND} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
