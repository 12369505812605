const blogs = {
  1: "Save Big with Fleet Cards",
  2: "Fintech Which Is Transforming Freight Like Never Before",
  3: "Digital payments-Innovating the Trucking Industry",
  4: "Attention, Transporters! Your Payment-Related Troubles Are About to Vanish",
  5: "Ultimate guide to Expense Management-Freight fast and save more",
  6: "What is the best payment gateway for your Freight business",
  7: "Benefits of Digital Payments for Fleet Management",
  8: "Implementing a Fleet Digital Payment System Best Practices",
  9: "The Future of Fleet Payments Emerging Trends and Innovations",
  10: "Top 10 Benefits of Expense Management",
  11: "From Basics to Solutions Why your business needs Payroll Manangement",
  12: "Fintech Payroll vs Traditional Payroll A Comparative Analysis",
};

export const routes = {
  index: "/",
  blogs: "/Blogs",
  blog: {
    blog_1: `blog/${generateBlogRoute("1")}`,
    blog_2: `blog/${generateBlogRoute("2")}`,
    blog_3: `blog/${generateBlogRoute("3")}`,
    blog_4: `blog/${generateBlogRoute("4")}`,
    blog_5: `blog/${generateBlogRoute("5")}`,
    blog_6: `blog/${generateBlogRoute("6")}`,
    blog_7: `blog/${generateBlogRoute("7")}`,
    blog_8: `blog/${generateBlogRoute("8")}`,
    blog_9: `blog/${generateBlogRoute("9")}`,
    blog_10: `blog/${generateBlogRoute("10")}`,
    blog_11: `blog/${generateBlogRoute("11")}`,
    blog_12: `blog/${generateBlogRoute("12")}`,
  },
  faqs: "/faqs",
  contact: "/contact",
  privacy: "/privacy",
  terms: "/terms",
  data_policy: "/data-policy",
  NOT_FOUND: "*",
};

export function generateBlogRoute(key) {
  return blogs[key].split(" ").join("-").toLowerCase();
}
