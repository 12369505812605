import React, { useEffect, useState } from "react";
import { OnlyInMobile, OnlyInPc } from "../Responsive/responsive.component";

import "./products.style.scss";

import MoxeyPay from "./imgs/products/moxey_products_pay.png";
import MoxeyScf from "./imgs/products/moxey_products_scf.png";
import MoxeyLeasing from "./imgs/products/moxey_products_leasing.png";
import MoxeyBnpl from "./imgs/products/moxey_products_bnpl.png";
import MoxeyFactoring from "./imgs/products/moxey_products_factoring.png";

// logos
import moxeyProductLogoPay from "./imgs/products/moxey_products_logo_pay.png";
import moxeyProductLogoScf from "./imgs/products/moxey_products_logo_scf.png";
import moxeyProductLogoLeasing from "./imgs/products/moxey_products_logo_leasing.png";
import moxeyProductLogoBnpl from "./imgs/products/moxey_products_logo_bnpl.png";
import moxeyProductLogoFactoring from "./imgs/products/moxey_products_logo_factoring.png";

// components
import PrimaryActionButton from "../ui/Buttons/primary-button.component";

const breakpoints = [2600, 3400, 4000, 4600, 5200];

const moxeyProducts = [
  {
    logo: moxeyProductLogoPay,
    headline: "Cashless Payments and Instant Settlements",
    description:
      "Get hassle-free and quick payments and settlements done through our app and card-based solutions",
    url: "https://play.google.com/store/apps/details?id=com.moxeypay",
    img: MoxeyPay,
    id: "MoxeyPay",
    button: "get moxey",
  },
  {
    logo: moxeyProductLogoScf,
    headline: "Optimize Working Capital in less than 24 hours",
    description:
      "Logistics service providers experience significant cash-flow problems. Optimize working capital quickly and improve your supplier resiliency with the help of MoXey SCF",
    url: "/contact",
    img: MoxeyScf,
    id: "MoxeyScf",
    button: "contact us",
  },
  {
    logo: moxeyProductLogoLeasing,
    headline: "Promote Microentrepreneurship",
    description:
      "Drivers are generally excluded from traditional banking services and rely heavily on informal finance providers. At MoXey, we make it easier for this under served sector to own their asset by providing easy financing terms.",
    url: "/contact",
    img: MoxeyLeasing,
    id: "MoxeyLeasing",
    button: "contact us",
  },
  {
    logo: moxeyProductLogoBnpl,
    headline: "Consumer Financing for Suppliers",
    description:
      "Get approved for a credit limit and utilize the money to finance all your purchases at our partner store. Build and improve your credit score by paying your bills on time.",
    url: "/contact",
    img: MoxeyBnpl,
    id: "MoxeyBnpl",
    button: "contact us",
  },
  {
    logo: moxeyProductLogoFactoring,
    headline: "Invoice Factoring for Suppliers",
    description: `With invoice factoring, the transporters will be able to get their payments instantly and boost cash flows to expand their business. 
    MoXey will pay the transporter upfront for any supplier invoice and then collects the payment from the supplier at the end of the agreed credit terms.`,
    url: "/contact",
    img: MoxeyFactoring,
    id: "MoxeyFactoring",
    button: "contact us",
  },
];

function ProductsComponent() {
  const [offset, setOffset] = useState(0);

  const onScroll = () => setOffset(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="moxey-products" id="moxey-products">
      <div className="container">
        <div className="moxey-products-header">
          <h1
            className="moxey-products-heading text-gradient animated"
            data-aos="fade-up"
          >
            Grow Your Business with Our Products
          </h1>
        </div>
        <div className="row w-100">
          <div className="col-sm-12 col-lg-6 d-flex align-items-center flex-column">
            {moxeyProducts.map((product, index) => (
              <MoxeyProduct product={product} key={index} />
            ))}
          </div>
          {/* static right section  */}
          <div className="col-sm-12 col-lg-6">
            <div className="moxey-products-img sticky-top d-flex justify-content-end">
              {offset > breakpoints[0] && offset < breakpoints[1] ? (
                <MoxeyCurrentProduct currentProduct={MoxeyPay} />
              ) : null}
              {offset > breakpoints[1] && offset < breakpoints[2] ? (
                <MoxeyCurrentProduct currentProduct={MoxeyScf} />
              ) : null}
              {offset > breakpoints[2] && offset < breakpoints[3] ? (
                <MoxeyCurrentProduct currentProduct={MoxeyLeasing} />
              ) : null}
              {offset > breakpoints[3] && offset < breakpoints[4] ? (
                <MoxeyCurrentProduct currentProduct={MoxeyBnpl} />
              ) : null}
              {offset > breakpoints[4] ? (
                <MoxeyCurrentProduct currentProduct={MoxeyFactoring} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MoxeyProduct({ product }) {
  const { logo, headline, description, url, img, id, button } = product;
  return (
    <div className="moxey-product w-100" id={id}>
      <img className="moxey-product-logo" src={logo} />
      <h1 className="moxey-product-headline">{headline}</h1>
      <div className="text-center">
        <OnlyInMobile>
          <img className="moxey-product-img d-" src={img} data-aos="fade-in" />
        </OnlyInMobile>
      </div>
      <p className="moxey-product-description">{description}</p>

      {button ? (
        <PrimaryActionButton className={"mt-5"} name={button} url={url} />
      ) : null}
    </div>
  );
}

function MoxeyCurrentProduct({ currentProduct }) {
  return (
    <img
      className="moxey-product-img d-none d-md-block d-lg-block"
      src={currentProduct}
      data-aos="fade-in"
    />
  );
}

export default ProductsComponent;
