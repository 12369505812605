import "./logo.style.scss";

const LogoComponent = () => {
  return (
    <svg
      className="moxey-logo"
      width="261.272"
      height="53.287"
      viewBox="0 0 261.272 53.287"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-88.978"
          y1="-35.805"
          x2="-87.978"
          y2="-35.805"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.037" stopColor="#006b38" />
          <stop offset="1" stopColor="#00883b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#26a737" />
          <stop offset="0.511" stopColor="#00476b" />
          <stop offset="1" stopColor="#00ac94" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0"
          y1="0.5"
          x2="1"
          y2="0.5"
        />
        <linearGradient
          id="linear-gradient-4"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#26a737" />
          <stop offset="1" stopColor="#00ac94" />
        </linearGradient>
      </defs>
      <g id="moxey-logo-x" transform="translate(-621 -542)">
        <g id="x" transform="translate(739.851 542)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M279.7,59.43a6.037,6.037,0,0,1,1.11.809c.643.742,1.188,1.572,1.821,2.324.685.809,1.483,1.53,2.132,2.37,1.115,1.442,2.594,2.625,3.289,4.362a8.719,8.719,0,0,1-1.468,9.415c-1.318,1.582-2.635,3.164-3.968,4.736-3.3,3.885-6.593,7.786-9.933,11.645a7.169,7.169,0,0,1-12.506-5.135,6.977,6.977,0,0,1,1.634-4.207q5.337-6.318,10.691-12.625a43.327,43.327,0,0,0,5.82-8.33,10.017,10.017,0,0,0,1.271-4.855,2.06,2.06,0,0,1,.119-.508C279.72,59.435,279.7,59.43,279.7,59.43Z"
            transform="translate(-260.17 -44.206)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M309.639,82.462h0a7.556,7.556,0,0,1-10.613-1.255L267.214,44.2a7.556,7.556,0,0,1,1.255-10.613h0a7.556,7.556,0,0,1,10.613,1.255l31.812,37.01A7.556,7.556,0,0,1,309.639,82.462Z"
            transform="translate(-262.779 -30.985)"
            fill="url(#linear-gradient-2)"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M306.415,71.7a6.038,6.038,0,0,1-1.11-.809c-.643-.742-1.188-1.572-1.821-2.324-.685-.809-1.483-1.53-2.132-2.37-1.115-1.442-2.594-2.625-3.289-4.362a8.719,8.719,0,0,1,1.468-9.415c1.318-1.582,2.635-3.164,3.968-4.736,3.3-3.885,6.593-7.786,9.933-11.645a7.169,7.169,0,0,1,12.506,5.135,6.977,6.977,0,0,1-1.634,4.207q-5.337,6.318-10.691,12.625a43.329,43.329,0,0,0-5.82,8.33,10.017,10.017,0,0,0-1.271,4.855,2.06,2.06,0,0,1-.119.508C306.4,71.7,306.415,71.7,306.415,71.7Z"
            transform="translate(-278.041 -31.793)"
            fill="url(#linear-gradient-3)"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M302.855,68.143a6.038,6.038,0,0,1-1.11-.809c-.643-.742-1.188-1.572-1.821-2.324-.685-.809-1.484-1.53-2.132-2.37-1.115-1.442-2.594-2.625-3.289-4.362a8.719,8.719,0,0,1,1.468-9.415c1.318-1.582,2.635-3.164,3.968-4.736,3.3-3.885,6.593-7.786,9.933-11.645a7.169,7.169,0,0,1,12.506,5.135,6.977,6.977,0,0,1-1.634,4.207q-5.337,6.318-10.691,12.625a43.328,43.328,0,0,0-5.82,8.33,10.017,10.017,0,0,0-1.271,4.855,2.059,2.059,0,0,1-.119.508C302.839,68.137,302.855,68.143,302.855,68.143Z"
            transform="translate(-276.328 -30.08)"
            fill="url(#linear-gradient-4)"
          />
        </g>
        <path
          id="Path_5"
          data-name="Path 5"
          d="M50.6,83.279H37.04V32.43H58.53l9.695,31.932H68.7L77.919,32.43H99.954V83.279H86.395l1.354-39.053h-.477L74.392,83.279H62.664L49.645,44.225h-.4Z"
          transform="translate(583.96 510.789)"
          fill="#fff"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M182.186,82.6a19.246,19.246,0,0,1-7.459-2.744,13.547,13.547,0,0,1-4.85-5.659,22.3,22.3,0,0,1-1.727-9.523V50.3a22.3,22.3,0,0,1,1.727-9.523,13.511,13.511,0,0,1,4.85-5.659,19.163,19.163,0,0,1,7.459-2.744,58.521,58.521,0,0,1,9.492-.711,59.112,59.112,0,0,1,9.492.711,19.246,19.246,0,0,1,7.459,2.744,13.547,13.547,0,0,1,4.85,5.659,22.3,22.3,0,0,1,1.727,9.523V64.67a22.3,22.3,0,0,1-1.727,9.523,13.511,13.511,0,0,1-4.85,5.659,19.163,19.163,0,0,1-7.459,2.744,58.522,58.522,0,0,1-9.492.711A57.879,57.879,0,0,1,182.186,82.6ZM196.15,71.279a6.093,6.093,0,0,0,2.951-1.084,4.8,4.8,0,0,0,1.629-2.511,16.01,16.01,0,0,0,.508-4.508V51.718a15.67,15.67,0,0,0-.508-4.476,4.767,4.767,0,0,0-1.629-2.474,6.125,6.125,0,0,0-2.951-1.084,36.073,36.073,0,0,0-4.476-.239,34.255,34.255,0,0,0-4.44.239,6.084,6.084,0,0,0-2.915,1.084,4.8,4.8,0,0,0-1.629,2.474,16.894,16.894,0,0,0-.576,4.476V63.177a17.512,17.512,0,0,0,.576,4.508,4.768,4.768,0,0,0,1.629,2.511,6.031,6.031,0,0,0,2.915,1.084,34.256,34.256,0,0,0,4.44.239A35.09,35.09,0,0,0,196.15,71.279Z"
          transform="translate(520.858 511.16)"
          fill="#fff"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M384.639,51.646h21.7v9.425h-21.7v9.9h23.6V82.089H371.08V31.24h36.678V42.361H384.639v9.285Z"
          transform="translate(423.189 511.362)"
          fill="#fff"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M475.614,64.251V82.084H462.055V64.251L444.02,31.23h15.525l9.083,19.6h.477l9.357-19.6h15.727Z"
          transform="translate(388.083 511.367)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default LogoComponent;
