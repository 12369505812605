import "./blog.style.scss";
import { Link } from "react-router-dom";
import shareIcon from "./share_icon.png";
import bookmarkIcon from "./bookmark_icon.png";

import { RWebShare } from "react-web-share";

import { BlogCard, blogs } from "../../components/Home/blogs.component";
import { Helmet } from "react-helmet-async";
import BlogThumbnail from "./imgs/Blogs_11.jpeg";
import { META_BLOGS } from "../../constants/meta-blogs";

import { generateBlogRoute } from "../../routes";

const RELATED_BLOGS_LIST = [...blogs];
function BlogView() {
  const blog = {
    title:
      "From Basics to Solutions: Why your business needs Payroll Manangement?",
    thumbnail: BlogThumbnail,
    base: "https://moxey.ai",
  };
  return (
    <div className="moxey-blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="title" content={blog.title} />
        <meta name="keywords" content={META_BLOGS[11].meta_keywords} />
        <meta name="description" content={META_BLOGS[11].meta_desc} />

        <meta property="og:site_name" content="MoXey - Beyond Payments" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={META_BLOGS[11].meta_desc} />
        <meta property="og:image" content={`${blog.base}${blog.thumbnail}`} />
        <meta
          property="og:url"
          content={`${blog.base}/blog/${generateBlogRoute("11")}`}
        />

        <meta name="twitter:site" content="@moxey.ai" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={META_BLOGS[11].meta_desc} />
        <meta
          name="twitter:image:src"
          content={`${blog.base}${blog.thumbnail}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`${blog.base}/blog/${generateBlogRoute("11")}`}
        />
      </Helmet>
      {/* blog header */}
      <div
        className="moxey-blog-header blog-bg-6 text-center "
        data-aos="fade-in"
      >
        <h1 className="moxey-blog-headline mb-4 fw-bold container">
          {blog.title}
        </h1>

        <div className="moxey-blog-timeline d-flex justify-content-center align-items-center">
          <div className="me-3">
            <small>August, 30 2023 - 4 min read</small>
          </div>
          <div>
            <RWebShare
              data={{
                text: document.title,
                url: document.URL,
                title: blog.title,
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="btn btn-sm rounded-circle">
                <img src={shareIcon} />
              </button>
            </RWebShare>
            <button className="btn btn-sm rounded-circle">
              <img src={bookmarkIcon} />
            </button>
          </div>
        </div>
      </div>

      {/* blog content */}
      <div className="container">
        <div
          className="moxey-blog-content shadow"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <h4 className="heading">
            Lets first understand what is Payroll Management?
          </h4>
          <p className="lead">
            Payroll management encompasses the systematic administration of
            employee compensation, benefits, deductions, and tax withholdings.
            Paychecks must be delivered on time, but it's also important to keep
            accurate records that comply with financial rules. Every aspect of
            the employee-employer relationship is impacted by an efficient
            payroll system, from ensuring employees are fairly compensated to
            reducing compliance concerns.
          </p>
          <p className="lead">
            Now let's check out the Benefits of Payroll Management:
          </p>
          <h6 className="heading">Accuracy and Compliance:</h6>
          <p>
            Payroll mistakes can result in expensive legal repercussions. With
            payroll management, you can ensure accurate salary, tax, and benefit
            calculations, putting your mind at ease about compliance.
          </p>
          <h6 className="heading">Time and Resource Efficiency:</h6>
          <p>
            By automating payroll procedures, you can free up your HR personnel
            to focus on more important activities that will help the business
            grow.
          </p>
          <h6 className="heading">Employee Satisfaction:</h6>
          <p>
            Processing payroll accurately and on time builds employee loyalty
            and trust. A satisfied staff produces more.
          </p>
          <h6 className="heading">Data Security:</h6>
          <p>
            Managing sensitive financial data requires a secure method. The risk
            of data breaches is reduced thanks to payroll management's assurance
            of data security and privacy.
          </p>
          <h6 className="heading">Tax Accuracy:</h6>
          <p>
            Tax laws can be complex and constantly changing. The possibility of
            errors is lowered by the regular updates and handling of tax
            calculations by payroll management systems.
          </p>
          <h4 className="heading">
            And Finally Why Your Business Needs Payroll Management?
          </h4>
          <h6 className="heading">Operational Efficiency:</h6>
          <p>
            Your team can concentrate on strategic goals because of the time and
            resources you save with improved payroll processes.
          </p>
          <h6 className="heading">Regulatory Adherence:</h6>
          <p>
            It's crucial to maintain compliance with tax laws, employee benefits
            requirements, and labor laws. You can avoid legal hazards by
            managing your payroll.
          </p>
          <h6 className="heading">Employee Trust and satisfaction:</h6>
          <p>
            Accurate and consistent paychecks foster a sense of trust among your
            employees, enhancing their job satisfaction and commitment.
          </p>
          <h6 className="heading">Cost Control:</h6>
          <p>
            Effective payroll administration minimizes overpayments,
            underpayments, and errors that could harm your business's bottom
            line.
          </p>
          <h6 className="heading">Scalability:</h6>
          <p>
            Your workforce grows as your business grows. Increasing complexity
            can be easily accommodated by an effective payroll system.
          </p>

          {/* Action BUttons */}
          <div className="actions my-5">
            <div className="d-grid col-sm-12 col-lg-2 mx-auto">
              <Link
                to="/contact"
                className="btn btn-success py-2  rounded-pill"
              >
                Request Demo
              </Link>
            </div>
          </div>

          <p className="lead">
            Payroll administration is more than just statistics; it's a crucial
            thread embedded into the fabric of your company for long-term
            success. Precision, efficiency, and the trust you develop with your
            team are all represented by it. By using MoXey's powerful payroll
            management system, you're nurturing the foundation for long-term
            growth as well as improving financial stability.
          </p>
          <p className="lead">
            Make MoXey Payroll Management your payroll provider today and
            transform your business.
          </p>
          
        </div>

        {/* moxey related blogs list */}
        <h4 className="text-center mt-5 py-5">Read more</h4>
        <div className="moxey-recommendations">
          {RELATED_BLOGS_LIST.map((blog, index) => (
            <BlogCard item={blog} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogView;
